import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupText,
} from "reactstrap"

// FlatPickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Import Editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import * as Yup from "yup"
import { useFormik } from "formik"
import SimpleBar from "simplebar-react"
import moment from "moment"
import { createProperty, getPropertyTypes } from "services/propertyService"
import { getAgents } from "services/agentsService"
import { toast } from "react-toastify"
import withRouter from "components/Common/withRouter"
import { Line } from "rc-progress"
import { fileUpload, getExtension } from "helpers/utils"

const PropertyCreate = props => {
  //meta title
  document.title = "Create New Property | ListProperty"

  const [loading, setLoading] = useState(false)
  const [imageFiles, setImageFiles] = useState([])
  const [documentFiles, setDocumentFiles] = useState([])
  const [agents, setAgents] = useState([])
  const [assignee, setAssignee] = useState("")
  const [dropList, setDropList] = useState(false)
  const [active, setActive] = useState(0)
  const [locations, setLocations] = useState([
    { address: "", longitude: "", latitude: "" },
  ])
  const [licenses, setLicenses] = useState([
    { issue_date: "", expiry_date: "", type: "", permit: "" },
  ])
  const [prices, setPrices] = useState([{ offer: "", value: "", period: "" }])
  const [amendities, setAmendities] = useState([{ title: "" }])
  const [customFields, setCustomFields] = useState([{ name: "", value: "" }])

  const [types, setTypes] = useState([
    {
      id: 1,
      title: "Villa",
    },
    {
      id: 2,
      title: "Apartment",
    },
    {
      id: 3,
      title: "Townhouse",
    },
    {
      id: 4,
      title: "Penthouse",
    },
    {
      id: 5,
      title: "Compound",
    },
  ])

  const [uploadImageProgress, setImageUploadProgress] = useState({})
  const [uploadDocumentProgress, setDocumentUploadProgress] = useState({})

  /**
   * Fetch inital data
   */
  const fetchBaseData = async () => {
    const typesResponse = await getPropertyTypes()
    setTypes(typesResponse)
    const agentsResponse = await getAgents()
    setAgents(agentsResponse)
  }

  useEffect(() => {
    fetchBaseData()
  }, [])

  const onImageDrop = useCallback(acceptedFiles => {
    const newFiles = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      progress: 0,
    }))

    setImageFiles(prevFiles => [...prevFiles, ...newFiles])

    const uploadPromises = newFiles.map(({ file }) => {
      const formData = new FormData()
      formData.append("file", file)

      return fileUpload(
        "images",
        `${new Date().getTime()}.${getExtension(file.name)}`,
        file,
        percent => {
          setImageUploadProgress(prev => ({ ...prev, [file.name]: percent }))
        }
      )
        .then(res => {
          setImageUploadProgress(prev => ({ ...prev, [file.name]: 100 }))
          return res // Return the uploaded file response
        })
        .catch(error => {
          toast.error(error)
          setImageUploadProgress(prev => ({ ...prev, [file.name]: 0 }))
          return null // Return null for failed uploads
        })
    })

    // Once all uploads are complete, update the form's images field
    Promise.all(uploadPromises).then(uploadedFiles => {
      const successfulUploads = uploadedFiles.filter(Boolean) // Remove failed uploads
      const updatedImages = [...validation.values.images, ...successfulUploads]
      validation.setFieldValue("images", updatedImages) // Update all at once
    })
  }, [])

  const onDocumentDrop = useCallback(acceptedFiles => {
    const newFiles = acceptedFiles.map(file => ({
      file,
      preview: URL.createObjectURL(file),
      progress: 0,
    }))

    setDocumentFiles(prevFiles => [...prevFiles, ...newFiles])

    const uploadPromises = newFiles.map(({ file }) => {
      const formData = new FormData()
      formData.append("file", file)

      return fileUpload(
        "images",
        `${new Date().getTime()}.${getExtension(file.name)}`,
        file,
        percent => {
          setDocumentUploadProgress(prev => ({ ...prev, [file.name]: percent }))
        }
      )
        .then(res => {
          setDocumentUploadProgress(prev => ({ ...prev, [file.name]: 100 }))
          return res // Return the uploaded file response
        })
        .catch(error => {
          toast.error(error)
          setDocumentUploadProgress(prev => ({ ...prev, [file.name]: 0 }))
          return null // Return null for failed uploads
        })
    })

    // Once all uploads are complete, update the form's images field
    Promise.all(uploadPromises).then(uploadedFiles => {
      const successfulUploads = uploadedFiles.filter(Boolean) // Remove failed uploads
      const updatedDocuments = [
        ...validation.values.notes,
        ...successfulUploads,
      ]
      validation.setFieldValue("notes", updatedDocuments) // Update all at once
    })
  }, [])

  const handleClick = item => {
    setActive(item.id)
    setAssignee(item)
    setDropList(false)
    validation.setFieldValue("agent_id", item.id)
  }

  // validation
  const validation = useFormik({
    initialValues: {
      property_type_id: "",
      reference: null,
      developer: null,
      charges: null,
      parking: null,
      exclusivity: [],
      location: [],
      size: null,
      bedrooms: null,
      bathrooms: null,
      status: "", // draft, available, rented, under_offer, reserved, sold
      category: "", // commercial, residential
      furnished: null, // furnished, unfurnished, semi_furnished
      unit_number: null,
      offering_type: null,
      license: [],
      viewLink: null,
      financial_status: null, // cash, mortgage, other
      floor_number: null,
      floors: null,
      price: [],
      free_hold: null, // Free_hold, Non_Free_hold, Lease_hold
      occupancy: null, // owner_occupied, investment, vacant, tenanted
      renovation: null, // fully_upgraded_indoor, fully_upgraded_outdoor, fully_upgraded_both, partially_upgraded_indoor, partially_upgraded_outdoor, partially_upgraded_both
      build_year: null,
      project_status: null, // completed, off_plan
      plot_size: null,
      built_up_area: null,
      project_name: null,
      street_number: null,
      street_name: null,
      available_from: null,
      images: [],
      notes: [],
      fields: [], // custom fields
      amendity: [],
      agent_id: null,
    },
    validationSchema: Yup.object({
      property_type_id: Yup.number()
        .required("Property type is required")
        .typeError("Property type should be a number"),
      reference: Yup.string().required("Referenece is required"),
      location: Yup.array()
        .of(Yup.mixed().required("Locations are required"))
        .min(1, "At least one location is required"),
      size: Yup.number()
        .required("Size is required")
        .typeError("Size must be a number"),
      bedrooms: Yup.number()
        .required("Number of bedrooms is required")
        .min(1, "Number of bedrooms must be more than 1")
        .typeError("Number of bedrooms must be a number"),
      bathrooms: Yup.number()
        .required("Number of bathrooms is required")
        .min(1, "Number of bathrooms must be more than 1")
        .typeError("Number of bathrooms must be a number"),
      status: Yup.string().required("Status is required"),
      description: Yup.string().required("Description is required"),
      category: Yup.string().required("Category is required"),
      price: Yup.array()
        .of(Yup.mixed().required("Prices are required"))
        .min(1, "At least one price is required"),
      offering_type: Yup.string().required("Offering type is required"),
      images: Yup.array()
        .of(Yup.mixed().required("Images are required"))
        .min(1, "At least one image is required"),
      agent_id: Yup.string().required("Assigned agent is required"),
    }),
    onSubmit: values => {
      createProperty(values)
        .then(() => props.router.navigate("/properties"))
        .catch(e => toast.error(e.message))
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Properties" breadcrumbItem="Create Property" />
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
            }}
          >
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row>
                      {/* Property Type */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="property_type_id">Property Type</Label>
                        <Input
                          type="select"
                          name="property_type_id"
                          className="form-control"
                          placeholder="Select property type."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.property_type_id || ""}
                          invalid={
                            validation.touched.property_type_id &&
                            validation.errors.property_type_id
                              ? true
                              : false
                          }
                        >
                          <option value={null}>Select property type</option>
                          {types.map((type, index) => (
                            <option key={index} value={type.id}>
                              {type.title}
                            </option>
                          ))}
                        </Input>
                        {validation.touched.property_type_id &&
                        validation.errors.property_type_id ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.property_type_id}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Status */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="propertyStatus">Property Status</Label>
                        <Input
                          type="select"
                          id="propertyStatus"
                          name="status"
                          className="form-control"
                          placeholder="Select property status."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.status || ""}
                          invalid={
                            validation.touched.status &&
                            validation.errors.status
                              ? true
                              : false
                          }
                        >
                          <option value={null}>Select property status</option>
                          <option value="available">Available</option>
                          <option value="rented">Rented</option>
                          <option value="under_offer">Under Offer</option>
                          <option value="reserved">Reserved</option>
                          <option value="sold">Sold</option>
                        </Input>
                        {validation.touched.status &&
                        validation.errors.status ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.status}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Reference */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="reference">Referenece</Label>
                        <Input
                          name="reference"
                          type="text"
                          placeholder="Enter property reference."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.reference || ""}
                          invalid={
                            validation.touched.reference &&
                            validation.errors.reference
                              ? true
                              : false
                          }
                        />
                        {validation.touched.reference &&
                        validation.errors.reference ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.reference}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Developer */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="develper">Developer</Label>
                        <Input
                          name="developer"
                          type="text"
                          placeholder="Enter property developer."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.developer || ""}
                        />
                        {validation.touched.developer &&
                        validation.errors.developer ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.developer}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Service charges */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="charges">Service Charges</Label>
                        <Input
                          name="charges"
                          type="number"
                          placeholder="Enter service charges to the property."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.charges || ""}
                        />
                        {validation.touched.charges &&
                        validation.errors.charges ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.charges}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Parking spaces */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="parking">Parking Spaces</Label>
                        <Input
                          name="parking"
                          type="number"
                          placeholder="Enter mumber of parking spaces."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.parking || ""}
                        />
                        {validation.touched.parking &&
                        validation.errors.parking ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.parking}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Exclusivity dates */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="exclusivity">Exclusivity</Label>
                        <FlatPickr
                          className="form-control d-block"
                          name="exclusivity"
                          placeholder="Select exclusivity dates"
                          options={{
                            mode: "range",
                            dateFormat: "d M, Y",
                          }}
                          onChange={customerdate =>
                            validation.setFieldValue("exclusivity", [
                              moment(customerdate[0]).format("DD MMMM ,YYYY"),
                              moment(customerdate[1]).format("DD MMMM ,YYYY"),
                            ])
                          }
                          value={validation.values.exclusivity || ""}
                        />
                        {validation.touched.exclusivity &&
                        validation.errors.exclusivity ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.exclusivity}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Property size */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="size">Size</Label>
                        <InputGroup>
                          <Input
                            name="size"
                            type="number"
                            placeholder="Enter property size."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.size || ""}
                            invalid={
                              validation.touched.size && validation.errors.size
                                ? true
                                : false
                            }
                          />
                          <InputGroupText>sqft</InputGroupText>
                        </InputGroup>
                        {validation.touched.size && validation.errors.size ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.size}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* bedrooms */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="bedrooms">Number of bedrooms</Label>
                        <Input
                          name="bedrooms"
                          type="number"
                          placeholder="Enter number of bedrooms."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.bedrooms || ""}
                          invalid={
                            validation.touched.bedrooms &&
                            validation.errors.bedrooms
                              ? true
                              : false
                          }
                        />
                        {validation.touched.bedrooms &&
                        validation.errors.bedrooms ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.bedrooms}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Bathrooms */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="bathrooms">Number of bathrooms</Label>
                        <Input
                          name="bathrooms"
                          type="number"
                          placeholder="Enter number of bathrooms."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.bathrooms || ""}
                          invalid={
                            validation.touched.bathrooms &&
                            validation.errors.bathrooms
                              ? true
                              : false
                          }
                        />
                        {validation.touched.bathrooms &&
                        validation.errors.bathrooms ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.bathrooms}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Offering Type */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="offering_type">Offering Type</Label>
                        <Input
                          type="select"
                          name="offering_type"
                          className="form-control"
                          placeholder="Select offering type"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.offering_type || ""}
                          invalid={
                            validation.touched.offering_type &&
                            validation.errors.offering_type
                              ? true
                              : false
                          }
                        >
                          <option value={null}>Select offering type</option>
                          <option value="sale">Sale</option>
                          <option value="rent">Rent</option>
                        </Input>
                        {validation.touched.offering_type &&
                        validation.errors.offering_type ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.offering_type}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Available from dates */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="exclusivity">Available From</Label>
                        <FlatPickr
                          className="form-control d-block"
                          name="available_from"
                          placeholder="Select start date available"
                          options={{
                            mode: "single",
                            dateFormat: "d M, Y",
                          }}
                          onChange={customerdate =>
                            validation.setFieldValue(
                              "available_from",
                              customerdate[0]
                            )
                          }
                          value={validation.values.available_from || ""}
                        />
                        {validation.touched.available_from &&
                        validation.errors.available_from ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.available_from}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Category */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="category">Property Category</Label>
                        <Input
                          name="category"
                          type="select"
                          className="form-control"
                          placeholder="Select property category."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category || ""}
                          invalid={
                            validation.touched.category &&
                            validation.errors.category
                              ? true
                              : false
                          }
                        >
                          <option value={null}>Select property category</option>
                          <option value="commercial">Commercial</option>
                          <option value="residential">Residential</option>
                        </Input>
                        {validation.touched.category &&
                        validation.errors.category ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.category}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Furnishing status */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="furnished">Furnishing Status</Label>
                        <select
                          name="furnished"
                          className="form-control"
                          placeholder="Select furnishing status"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.furnished || ""}
                        >
                          <option value={null}>Select furnishing status</option>
                          <option value="furnished">Furnished</option>
                          <option value="unfurnished">Unfurnished</option>
                          <option value="semi_furnished0">
                            Semi-furnished
                          </option>
                        </select>
                        {validation.touched.furnished &&
                        validation.errors.furnished ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.furnished}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Unit number */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="unit_number">
                          Building Unit Number
                        </Label>
                        <Input
                          name="unit_number"
                          type="text"
                          placeholder="Enter building unit number."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.unit_number || ""}
                        />
                        {validation.touched.unit_number &&
                        validation.errors.unit_number ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.unit_number}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Locations */}
                      <Col lg={12} className="mb-3">
                        <Label htmlFor="locations">Locations</Label>
                        {locations.map((location, index) => (
                          <Row key={index}>
                            <Col lg={5} className="my-1">
                              <Input
                                type="text"
                                placeholder="Address"
                                value={location.address}
                                onChange={e => {
                                  let temp = [...locations]
                                  temp[index].address = e.target.value
                                  setLocations(temp)
                                  validation.setFieldValue(
                                    "location",
                                    locations
                                  )
                                }}
                              />
                            </Col>
                            <Col
                              lg={7}
                              className="d-flex align-items-center my-1"
                            >
                              <Input
                                type="text"
                                value={location.longitude}
                                onChange={e => {
                                  let temp = [...locations]
                                  temp[index].longitude = e.target.value
                                  setLocations(temp)
                                  validation.setFieldValue(
                                    "location",
                                    locations
                                  )
                                }}
                                placeholder="Longitude"
                                className="me-2 me-md-4"
                              />
                              <Input
                                type="text"
                                placeholder="Latitude"
                                value={location.latitude}
                                onChange={e => {
                                  let temp = [...locations]
                                  temp[index].latitude = e.target.value
                                  setLocations(temp)
                                  validation.setFieldValue(
                                    "location",
                                    locations
                                  )
                                }}
                              />
                              {locations.length === 1 ? null : (
                                <Button
                                  type="button"
                                  onClick={() => {
                                    let temp = [...locations]
                                    temp.splice(index, 1)
                                    setLocations(temp)
                                    validation.setFieldValue(
                                      "location",
                                      locations
                                    )
                                  }}
                                  className="btn-sm btn btn-danger ms-2"
                                >
                                  <i className="bx bx-trash" />
                                </Button>
                              )}
                            </Col>
                          </Row>
                        ))}
                        <div className="my-1">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setLocations([
                                ...locations,
                                {
                                  address: "",
                                  longitude: "",
                                  latitude: "",
                                },
                              ])
                            }}
                          >
                            Add Location
                          </button>
                        </div>
                        {validation.touched.location &&
                        validation.errors.location ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.location}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* License */}
                      <Col sm={12}>
                        <Label htmlFor="license">Licenses</Label>
                        {licenses.map((license, index) => (
                          <Row key={index}>
                            <Col xl={4} className="mb-1">
                              <select
                                name="license_type"
                                className="form-control"
                                placeholder="Select license type."
                                value={license.type}
                                onChange={e => {
                                  let temp = [...licenses]
                                  temp[index].type = e.target.value
                                  setLicenses(temp)
                                  validation.setFieldValue("license", temp)
                                }}
                              >
                                <option value={null}>
                                  Select license type
                                </option>
                                <option value="dtcm">DTCM</option>
                                <option value="rera">RERA</option>
                                <option value="rera_listing">
                                  RERA Listing
                                </option>
                              </select>
                            </Col>
                            <Col xl={4} className="mb-1">
                              <Input
                                name="license_permit"
                                type="text"
                                placeholder="license permit."
                                value={license.permit}
                                onChange={e => {
                                  let temp = [...licenses]
                                  temp[index].permit = e.target.value
                                  setLicenses(temp)
                                  validation.setFieldValue("license", temp)
                                }}
                              />
                            </Col>
                            <Col
                              xl={4}
                              className="mb-1 d-flex align-items-center"
                            >
                              <FlatPickr
                                className="form-control d-block"
                                name="exclusivity"
                                placeholder="Select license period"
                                options={{
                                  mode: "range",
                                  dateFormat: "d M, Y",
                                }}
                                onChange={customerdate => {
                                  let temp = [...licenses]
                                  temp[index].issue_date = moment(
                                    customerdate[0]
                                  ).format("DD MMMM ,YYYY")
                                  temp[index].expiry_date = moment(
                                    customerdate[1]
                                  ).format("DD MMMM ,YYYY")
                                  setLicenses(temp)
                                }}
                                value={[
                                  license.issue_date,
                                  license.expiry_date,
                                ]}
                              />
                              <Button
                                type="button"
                                onClick={() => {
                                  let temp = [...licenses]
                                  temp.splice(index, 1)
                                  setLicenses(temp)
                                  validation.setFieldValue("license", temp)
                                }}
                                className="btn-sm btn btn-danger ms-2"
                              >
                                <i className="bx bx-trash" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <div className="mb-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setLicenses([
                                ...licenses,
                                {
                                  issue_date: "",
                                  expiry_date: "",
                                  type: "",
                                  permit: "",
                                },
                              ])
                            }}
                          >
                            Add License
                          </button>
                        </div>
                      </Col>

                      {/* Prices */}
                      <Col sm={12} className="mb-3">
                        <Label htmlFor="price">Prices</Label>
                        {prices.map((price, index) => (
                          <Row key={index}>
                            {/* Offering Type */}
                            <Col xl={4} className="mb-1">
                              <select
                                name="offering_type"
                                className="form-control"
                                placeholder="Select offering type."
                                onChange={e => {
                                  let temp = [...prices]
                                  temp[index].offer = e.target.value
                                  setPrices(temp)
                                  validation.setFieldValue("price", temp)
                                }}
                                value={price.offer}
                              >
                                <option value={null}>
                                  Select offering type
                                </option>
                                <option value="rent">Rent</option>
                                <option value="sale">Sale</option>
                              </select>
                            </Col>
                            <Col xl={4} className="mb-1">
                              <InputGroup>
                                <Input
                                  type="number"
                                  placeholder="Price"
                                  value={price.value}
                                  onChange={e => {
                                    let temp = [...prices]
                                    temp[index].value = e.target.value
                                    setPrices(temp)
                                    validation.setFieldValue("price", temp)
                                  }}
                                />
                                <InputGroupText>AED</InputGroupText>
                              </InputGroup>
                            </Col>
                            <Col
                              xl={4}
                              className="mb-1 d-flex align-items-center"
                            >
                              {price.offer === "rent" && (
                                <select
                                  className="form-control me-2"
                                  placeholder="Select rent period."
                                  onChange={e => {
                                    let temp = [...prices]
                                    temp[index].period = e.target.value
                                    setPrices(temp)
                                    validation.setFieldValue("price", temp)
                                  }}
                                  value={price.period}
                                  required
                                >
                                  <option value={null}>
                                    Select rent period
                                  </option>
                                  <option value="year">Year</option>
                                  <option value="month">Month</option>
                                  <option value="week">Week</option>
                                  <option value="day">Day</option>
                                </select>
                              )}
                              {prices.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    let temp = [...prices]
                                    temp.splice(index, 1)
                                    setPrices(temp)
                                    validation.setFieldValue("price", temp)
                                  }}
                                  className="btn-sm btn btn-danger"
                                >
                                  <i className="bx bx-trash" />
                                </button>
                              )}
                            </Col>
                          </Row>
                        ))}
                        <div>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setPrices([
                                ...prices,
                                { offer: "", value: "", period: "" },
                              ])
                            }}
                          >
                            Add Price
                          </button>
                        </div>
                        {validation.touched.price && validation.errors.price ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.price}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Financial status */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="financial_status">
                          Financial Status
                        </Label>
                        <select
                          name="financial_status"
                          className="form-control"
                          placeholder="Select rent period."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.financial_status || ""}
                        >
                          <option value={null}>Select financial_status</option>
                          <option value="cash">Cash</option>
                          <option value="mortgage">Mortgage</option>
                          <option value="other">Other</option>
                        </select>
                        {validation.touched.financial_status &&
                        validation.errors.financial_status ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.financial_status}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Floor number */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="floor_number">Floor Number</Label>
                        <Input
                          name="floor_number"
                          type="number"
                          placeholder="Enter floor number."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.floor_number || ""}
                        />
                        {validation.touched.floor_number &&
                        validation.errors.floor_number ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.floor_number}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Floors */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="floors">Number of Floors</Label>
                        <Input
                          name="floors"
                          type="number"
                          placeholder="Enter number of floors."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.floors || ""}
                        />
                        {validation.touched.floors &&
                        validation.errors.floors ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.floors}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Free hold */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="free_hold">Holding Type</Label>
                        <select
                          name="free_hold"
                          className="form-control"
                          placeholder="Select holding type."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.free_hold || ""}
                        >
                          <option value={null}>Select holding type</option>
                          <option value="Free_hold">Free hold</option>
                          <option value="Non_Free_hold">Non free hold</option>
                          <option value="Lease_hold">Lease hold</option>
                        </select>
                        {validation.touched.free_hold &&
                        validation.errors.free_hold ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.free_hold}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Occupancy */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="occupancy">Occupancy</Label>
                        <select
                          name="occupancy"
                          className="form-control"
                          placeholder="Select occupancy."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.occupancy || ""}
                        >
                          <option value={null}>Select occupancy</option>
                          <option value="owner_occupied">Owner Occupied</option>
                          <option value="investment">Investment</option>
                          <option value="vacant">Vacant</option>
                          <option value="tenanted">Tenanted</option>
                        </select>
                        {validation.touched.occupancy &&
                        validation.errors.occupancy ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.occupancy}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Renovation */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="renovation">Renovation</Label>
                        <select
                          name="renovation"
                          className="form-control"
                          placeholder="Select renovation."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.renovation || ""}
                        >
                          <option value={null}>Select renovation</option>
                          <option value="fully_upgraded_indoor">
                            Fully Upgraded Indoor
                          </option>
                          <option value="fully_upgraded_outdoor">
                            Fully Upgraded Outdoor
                          </option>
                          <option value="fully_upgraded_both">Vacant</option>
                          <option value="partially_upgraded_indoor">
                            Partially Upgraded Indoor
                          </option>
                          <option value="partially_upgraded_outdoor">
                            Partially Upgraded Outdoor
                          </option>
                          <option value="partially_upgraded_both">
                            Partially Upgraded Both
                          </option>
                        </select>
                        {validation.touched.renovation &&
                        validation.errors.renovation ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.renovation}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Build year */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="build_year">Build Year</Label>
                        <Input
                          name="build_year"
                          type="text"
                          placeholder="Enter build year."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.build_year || ""}
                        />
                        {validation.touched.build_year &&
                        validation.errors.build_year ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.build_year}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Project status */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="project_status">Project Status</Label>
                        <select
                          name="project_status"
                          className="form-control"
                          placeholder="Select project status."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.project_status || ""}
                        >
                          <option value={null}>Select project status.</option>
                          <option value="completed">Completed</option>
                          <option value="off_plan">Off-plan</option>
                        </select>
                        {validation.touched.project_status &&
                        validation.errors.project_status ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.project_status}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Plot size */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="plot_size">Plot Size</Label>
                        <Input
                          name="plot_size"
                          type="number"
                          placeholder="Enter plot size."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.plot_size || ""}
                        />
                        {validation.touched.plot_size &&
                        validation.errors.plot_size ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.plot_size}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Built up area */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="built_up_area">Built Up Area</Label>
                        <Input
                          name="built_up_area"
                          type="number"
                          placeholder="Enter built up area."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.built_up_area || ""}
                        />
                        {validation.touched.built_up_area &&
                        validation.errors.built_up_area ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.built_up_area}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Project Name */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="project_name">Project Name</Label>
                        <Input
                          name="project_name"
                          type="text"
                          placeholder="Enter project name."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.project_name || ""}
                        />
                        {validation.touched.project_name &&
                        validation.errors.project_name ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.project_name}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Street number */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="street_number">Street Number</Label>
                        <Input
                          name="street_number"
                          type="number"
                          placeholder="Enter street number."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.street_number || ""}
                        />
                        {validation.touched.street_number &&
                        validation.errors.street_number ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.street_number}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Street name */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="street_name">Street Name</Label>
                        <Input
                          name="street_name"
                          type="text"
                          placeholder="Enter street name."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.street_name || ""}
                        />
                        {validation.touched.street_name &&
                        validation.errors.street_name ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.street_name}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* View Link */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="viewLink">Virtual Tour Link</Label>
                        <Input
                          name="viewLink"
                          type="text"
                          placeholder="Enter virtual tour link."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.viewLink || ""}
                        />
                        {validation.touched.viewLink &&
                        validation.errors.viewLink ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.viewLink}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Assingnee */}
                      <Col xxl={4} lg={6} className="mb-3 position-relative">
                        <Label htmlFor="task-assign-input">Assign To</Label>
                        <div className="d-flex">
                          <div
                            className="avatar-group ps-0 justify-content-center"
                            id="assignee-member"
                          >
                            {assignee && (
                              <React.Fragment>
                                <Link
                                  to="#"
                                  className="avatar-group-item mb-2 mx-1"
                                  id={`assignee-member`}
                                >
                                  {assignee.avatar ? (
                                    <img
                                      src={assignee.avatar}
                                      alt=""
                                      className="rounded-circle avatar-xs"
                                    />
                                  ) : (
                                    <div className="avatar-xs">
                                      <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                        {assignee.full_name.charAt(0)}
                                      </span>
                                    </div>
                                  )}
                                </Link>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`assignee-member`}
                                >
                                  {assignee.full_name}
                                </UncontrolledTooltip>
                              </React.Fragment>
                            )}
                          </div>

                          <div
                            className="select-element w-100"
                            id="select-element"
                          >
                            <button
                              className="btn btn-light w-100 d-flex justify-content-between"
                              type="button"
                              onClick={() => setDropList(!dropList)}
                            >
                              <span>
                                Assigned To
                                <b id="total-assignee" className="mx-1">
                                  {assignee.full_name
                                    ? assignee.full_name
                                    : "Nobody"}
                                </b>
                              </span>{" "}
                              <i className="mdi mdi-chevron-down"></i>
                            </button>
                            <div
                              className={`w-100 dropdown-menu ${
                                dropList ? "show" : ""
                              }`}
                            >
                              <SimpleBar
                                data-simplebar="init"
                                style={{ maxHeight: "172px" }}
                              >
                                <ul className="list-unstyled mb-0 assignto-list">
                                  {(agents || [])?.map((item, index) => (
                                    <a
                                      className={`d-flex align-items-center dropdown-item ${
                                        active === item.id ? "active" : ""
                                      }`}
                                      href="#!"
                                      key={index}
                                      onClick={() => handleClick(item)}
                                    >
                                      <div className="avatar-xs flex-shrink-0 me-2">
                                        {item.avatar ? (
                                          <img
                                            src={item.avatar}
                                            alt=""
                                            className="rounded-circle avatar-xs"
                                          />
                                        ) : (
                                          <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                              {item.full_name.charAt(0)}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="flex-grow-1">
                                        {item.full_name}
                                      </div>
                                    </a>
                                  ))}
                                </ul>
                              </SimpleBar>
                            </div>
                          </div>
                        </div>

                        {validation.touched.agent_id &&
                        validation.errors.agent_id ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.agent_id}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Amendities */}
                      <Col xxl={4} lg={6} className="mb-3">
                        <Label htmlFor="amendity">Amendities</Label>
                        {amendities.map((amendity, index) => (
                          <Row key={index}>
                            <Col
                              sm={12}
                              className="mb-1 d-flex align-items-center"
                            >
                              <Input
                                type="text"
                                placeholder="Amendity"
                                value={amendity.title}
                                onChange={e => {
                                  let temp = [...amendities]
                                  temp[index].title = e.target.value
                                  setAmendities(temp)
                                  validation.setFieldValue("amendity", temp)
                                }}
                              />
                              <Button
                                type="button"
                                onClick={() => {
                                  let temp = [...amendities]
                                  temp.splice(index, 1)
                                  setAmendities(temp)
                                  validation.setFieldValue("amendity", temp)
                                }}
                                className="btn-sm btn btn-danger ms-2"
                              >
                                <i className="bx bx-trash" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <div className="mb-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setAmendities([
                                ...amendities,
                                {
                                  title: "",
                                },
                              ])
                            }}
                          >
                            Add Amemdity
                          </button>
                        </div>
                      </Col>

                      {/* Custom fields */}
                      <Col lg={8} className="mb-3">
                        <Label htmlFor="fields">Custom Fields</Label>
                        {customFields.map((field, index) => (
                          <Row key={index}>
                            <Col lg={6} className="mb-1">
                              <Input
                                type="text"
                                placeholder="Field Name"
                                value={field.name}
                                onChange={e => {
                                  let temp = [...customFields]
                                  temp[index].name = e.target.value
                                  setCustomFields(temp)
                                  validation.setFieldValue("fields", temp)
                                }}
                              />
                            </Col>
                            <Col
                              lg={6}
                              className="mb-1 d-flex align-items-center"
                            >
                              <Input
                                type="text"
                                placeholder="Field Value"
                                value={field.value}
                                onChange={e => {
                                  let temp = [...customFields]
                                  temp[index].value = e.target.value
                                  setCustomFields(temp)
                                  validation.setFieldValue("fields", temp)
                                }}
                              />
                              <Button
                                type="button"
                                onClick={() => {
                                  let temp = [...customFields]
                                  temp.splice(index, 1)
                                  setCustomFields(temp)
                                  validation.setFieldValue("fields", temp)
                                }}
                                className="btn-sm btn btn-danger ms-2"
                              >
                                <i className="bx bx-trash" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <div className="mb-3">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setCustomFields([
                                ...customFields,
                                {
                                  name: "",
                                  value: "",
                                },
                              ])
                            }}
                          >
                            Add Field
                          </button>
                        </div>
                      </Col>

                      {/* Description */}
                      <Col lg={12} className="mb-3">
                        <Label htmlFor="description">
                          Property Description
                        </Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={validation.values.description || ""}
                          config={{
                            placeholder: "Enter property description.",
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData()
                            validation.setFieldValue("description", data)
                          }}
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.description}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Images */}
                      <Col lg={6}>
                        <Label>Property Images</Label>
                        <Dropzone
                          onDrop={onImageDrop}
                          accept={{ "image/*": [] }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {imageFiles.map((file, index) => {
                            return (
                              <div className="border rounded" key={index}>
                                <div className="d-flex flex-wrap gap-2 p-2">
                                  <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm bg-light rounded p-2">
                                      <img
                                        data-dz-thumbnail=""
                                        className="img-fluid rounded d-block"
                                        src={file.preview}
                                        alt={file.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="pt-1">
                                      <h5 className="fs-md mb-1" data-dz-name>
                                        {file.path}
                                      </h5>
                                      <strong
                                        className="error text-danger"
                                        data-dz-errormessage
                                      ></strong>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 ms-3">
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() => {
                                        let newImages = [...imageFiles]
                                        newImages.splice(index, 1)
                                        setImageFiles(newImages)

                                        newImages = [
                                          ...validation.values.images,
                                        ]
                                        newImages.splice(index, 1)
                                        validation.setFieldValue(
                                          "images",
                                          newImages
                                        )
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                                {uploadImageProgress[file.file.name] &&
                                  uploadImageProgress[file.file.name] !==
                                    100 && (
                                    <>
                                      <Line
                                        percent={
                                          uploadImageProgress[file.file.name]
                                        }
                                        strokeWidth="1"
                                        strokeColor="#2db7f5"
                                      />
                                    </>
                                  )}
                              </div>
                            )
                          })}
                        </div>

                        {validation.errors.images &&
                        validation.touched.images ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.images}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      {/* Documents */}
                      <Col lg={6}>
                        <Label>Ownership Document Files</Label>
                        <Dropzone onDrop={onDocumentDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="document-previews"
                        >
                          {documentFiles.map((file, index) => {
                            return (
                              <div className="border rounded" key={index}>
                                <div className="d-flex p-2 justify-content-between">
                                  <div className="flex-grow-0">
                                    <div className="pt-1">
                                      <h5 className="fs-md mb-1" data-dz-name>
                                        {file.file.name}
                                      </h5>
                                      <strong
                                        className="error text-danger"
                                        data-dz-errormessage
                                      ></strong>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 ms-3">
                                    <Button
                                      variant="danger"
                                      size="sm"
                                      onClick={() => {
                                        let newDocuments = [...documentFiles]
                                        newDocuments.splice(index, 1)
                                        setDocumentFiles(newDocuments)

                                        newDocuments = [
                                          ...validation.values.notes,
                                        ]
                                        newDocuments.splice(index, 1)
                                        validation.setFieldValue(
                                          "notes",
                                          newDocuments
                                        )
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                                {uploadDocumentProgress[file.file.name] &&
                                  uploadDocumentProgress[file.file.name] !==
                                    100 && (
                                    <>
                                      <Line
                                        percent={
                                          uploadDocumentProgress[file.file.name]
                                        }
                                        strokeWidth="1"
                                        strokeColor="#2db7f5"
                                      />
                                    </>
                                  )}
                              </div>
                            )
                          })}
                        </div>

                        {validation.errors.notes && validation.touched.notes ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.notes}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <div className="mb-4">
                  <Button type="button" color="primary" className="me-2">
                    Save Draft
                  </Button>
                  <Button type="submit" color="success" disabled={loading}>
                    {loading ? "Submitting..." : "Create Property"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PropertyCreate)
