import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import { GET_EARNING_DATA } from "./actionType"
import { DashboardSaasapiSuccess, DashboardSaasapiFail } from "./actions"

//Include Both Helper File with needed methods
import {
  topSellingData as topSellingDataApi,
  getEarningChartsData as getEarningChartsDataApi,
} from "../../helpers/fakebackend_helper"

function* getEarningChartsData({ payload: month }) {
  try {
    var response = yield call(getEarningChartsDataApi, month)
    yield put(DashboardSaasapiSuccess(GET_EARNING_DATA, response))
  } catch (error) {
    yield put(DashboardSaasapiFail(GET_EARNING_DATA, error))
  }
}

export function* watchGetEarningChartsData() {
  yield takeEvery(GET_EARNING_DATA, getEarningChartsData)
}

function* dashboardSaga() {
  yield all([fork(watchGetEarningChartsData)])
}

export default dashboardSaga
