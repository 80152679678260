import React from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import MyProperties from "./MyProperties"

const JobGrid = () => {
  document.title = "My properties | ListProperty"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Properties" breadcrumbItem="My Properties" />
          <MyProperties />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default JobGrid
