import React, { Fragment } from "react"
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import { Col, Row } from "reactstrap"

const reports = [
  {
    icon: "bx bx-copy-alt",
    title: "Active",
    value: 12,
    badgeValue: "+ 0.2%",
    color: "success",
    desc: "From previous period",
  },
  {
    icon: "bx bx-archive-in",
    title: "Completed",
    value: 3,
    badgeValue: "+ 0.2%",
    color: "success",
    desc: "From previous period",
  },
  {
    icon: "bx bx-purchase-tag-alt",
    title: "Archieved",
    value: 1,
    badgeValue: "0%",
    color: "warning",
    desc: "From previous period",
  },
]
const summary = [
  {
    title: "Total Propertites",
    value: 48,
  },
  {
    title: "Total Agents",
    value: 40,
  },
  {
    title: "Total Contracts",
    value: 8,
  },
  ,
  {
    title: "Pending Request",
    value: 4,
  },
]

const CompanyDashboard = () => {
  return (
    <Fragment>
      {/* Card User */}
      <CardUser summary={summary} />

      <Row>
        {/* welcome card */}
        <CardWelcome />

        <Col xl="8">
          <Row>
            {/*mimi widgets */}
            <MiniWidget reports={reports} />
          </Row>
        </Col>
      </Row>

      <Row>
        {/* earning */}
        <Earning dataColors='["--bs-primary"]' />

        {/* sales anytics */}
        <SalesAnalytics dataColors='["--bs-primary", "--bs-success", "--bs-danger"]' />
      </Row>
    </Fragment>
  )
}

export default CompanyDashboard
