//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"
export const DELETE_MESSAGE = "/delete/message"

//CONTRACTS
export const GET_CONTRACTS = "/contracts"
export const GET_CONTRACTS_DETAIL = "/contract"

//dashboard jobs
export const GET_EARNING_DATA = "/earning-charts-data"

// Properties
export const PROPERTIES = "/properties"
export const PROPERTY_TYPES = "/property-types"

// Agents
export const GET_AGENTS = "/users/agents"
export const CREATE_AGENT = "/users/create-agent"

// contract requests
export const CONTRACT_REQUESTS = "/contract-requests"
export const UPDATE = "/update"
export const SIGN = "/sign"
export const REJECT = "/reject"

// contracts
export const CONTRACTS = "/contracts"
