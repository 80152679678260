import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
  Form,
  UncontrolledTooltip,
} from "reactstrap"
import { createSelector } from "reselect"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"
import CompanyProfile from "./company-profile"
import AgentProfile from "./agent-profile"

const invalidPasswordMessage =
  "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."

const ProfilePage = () => {
  //meta title
  document.title = "Profile | ListProperty"
  const [selectedImage, setSelectedImage] = useState("")

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const [isCompany, setIsCompany] = useState(false)
  useEffect(() => {
    setIsCompany(user.role === "company")
    setSelectedImage(user.avatar)
  }, [user])

  //  img upload
  const handleImageChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]
    reader.onloadend = () => {
      setSelectedImage(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const ProfileProperties = createSelector(
    state => state.Profile,
    profile => ({
      error: profile.error,
      success: profile.success,
    })
  )
  const { error, success } = useSelector(ProfileProperties)

  const changePasswordValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      newPassword: "",
      retypePassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .required("Current password is required")
        .min(8, "Current password must be at least 8 characters")
        .matches(/[A-Z]/, invalidPasswordMessage)
        .matches(/[a-z]/, invalidPasswordMessage)
        .matches(/[0-9]/, invalidPasswordMessage)
        .matches(/[@$!%*?&#]/, invalidPasswordMessage),
      newPassword: Yup.string()
        .required("New password is required")
        .min(8, "New password must be at least 8 characters")
        .matches(/[A-Z]/, invalidPasswordMessage)
        .matches(/[a-z]/, invalidPasswordMessage)
        .matches(/[0-9]/, invalidPasswordMessage)
        .matches(/[@$!%*?&#]/, invalidPasswordMessage),
      retypePassword: Yup.string()
        .required("Please retype your password")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: values => {},
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Home" breadcrumbItem="Profile" />

          <Row>
            <Col xxl="3" xl="4" lg="6">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody className="text-center">
                  <div className="position-relative d-inline-block">
                    <div className="position-absolute bottom-0 end-0">
                      <Label
                        htmlFor="project-image-input"
                        className="mb-0"
                        id="projectImageInput"
                      >
                        <div className="avatar-xs">
                          <div className="avatar-title bg-primary border rounded-circle text-white cursor-pointer shadow font-size-16">
                            <i className="bx bx-edit-alt"></i>
                          </div>
                        </div>
                      </Label>
                      <UncontrolledTooltip
                        placement="right"
                        target="projectImageInput"
                      >
                        Select Image
                      </UncontrolledTooltip>
                      <input
                        className="form-control d-none"
                        id="project-image-input"
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleImageChange}
                      />
                    </div>
                    <div className="avatar-lg">
                      <div className="avatar-title bg-light rounded-circle">
                        <img
                          src={selectedImage || ""}
                          id="projectlogo-img"
                          alt=""
                          className="avatar-lg h-auto rounded-circle"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <div className="text-muted">
                      <h5>{user.fullName}</h5>
                      <p className="mb-1">{user.email}</p>
                      <p className="mb-0">+1 234 567 8901</p>
                    </div>
                  </div>
                  <ul className="list-unstyled">
                    <i className="mx-1">
                      <button className="btn btn-soft-primary rounded-circle">
                        <i className="bx bxl-facebook" />
                      </button>
                    </i>
                    <i className="mx-1">
                      <button className="btn btn-soft-primary rounded-circle">
                        <i className="bx bxl-twitter" />
                      </button>
                    </i>
                    <i className="mx-1">
                      <button className="btn btn-soft-primary rounded-circle">
                        <i className="bx bxl-instagram" />
                      </button>
                    </i>
                    <i className="mx-1">
                      <button className="btn btn-soft-primary rounded-circle">
                        <i className="bx bxl-linkedin" />
                      </button>
                    </i>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col xxl="9" xl="8" lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Edit Profile</CardTitle>
                  {isCompany ? <CompanyProfile /> : <AgentProfile />}
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Change Password</CardTitle>
                  <Form onSubmit={changePasswordValidation.handleSubmit}>
                    <Row className="mb-3">
                      <Label className="col-sm-3 col-form-label">
                        Current Password
                      </Label>
                      <Col sm={9}>
                        <Input
                          name="currentPassword"
                          type="text"
                          placeholder="Enter current password"
                          onChange={changePasswordValidation.handleChange}
                          onBlur={changePasswordValidation.handleBlur}
                          value={
                            changePasswordValidation.values.currentPassword ||
                            ""
                          }
                          invalid={
                            changePasswordValidation.touched.currentPassword &&
                            changePasswordValidation.errors.currentPassword
                              ? true
                              : false
                          }
                        />
                        {changePasswordValidation.touched.currentPassword &&
                        changePasswordValidation.errors.currentPassword ? (
                          <FormFeedback type="invalid">
                            {changePasswordValidation.errors.currentPassword}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-sm-3 col-form-label">
                        New Password
                      </Label>
                      <Col sm={9}>
                        <Input
                          name="newPassword"
                          type="text"
                          placeholder="Enter new password"
                          onChange={changePasswordValidation.handleChange}
                          onBlur={changePasswordValidation.handleBlur}
                          value={
                            changePasswordValidation.values.newPassword || ""
                          }
                          invalid={
                            changePasswordValidation.touched.newPassword &&
                            changePasswordValidation.errors.newPassword
                              ? true
                              : false
                          }
                        />
                        {changePasswordValidation.touched.newPassword &&
                        changePasswordValidation.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            {changePasswordValidation.errors.newPassword}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Label className="col-sm-3 col-form-label">
                        Confirm Password
                      </Label>
                      <Col sm={9}>
                        <Input
                          name="retypePassword"
                          type="text"
                          placeholder="Retype password"
                          onChange={changePasswordValidation.handleChange}
                          onBlur={changePasswordValidation.handleBlur}
                          value={
                            changePasswordValidation.values.retypePassword || ""
                          }
                          invalid={
                            changePasswordValidation.touched.retypePassword &&
                            changePasswordValidation.errors.retypePassword
                              ? true
                              : false
                          }
                        />
                        {changePasswordValidation.touched.retypePassword &&
                        changePasswordValidation.errors.retypePassword ? (
                          <FormFeedback type="invalid">
                            {changePasswordValidation.errors.retypePassword}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="justify-content-end">
                      <Col sm={9}>
                        <Button type="submit" color="primary" className="w-md">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ProfilePage)
