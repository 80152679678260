import { SIGNER } from "constants/enum"
import axiosInstance from "helpers/axios_helper"
import {
  CONTRACT_REQUESTS,
  CONTRACTS,
  REJECT,
  SIGN,
  UPDATE,
} from "helpers/url_helper"

/**
 * Send requet to get contract requests
 * @param {*} isCompany
 * @returns
 */
export const getContractReqeusts = isCompany => {
  return axiosInstance
    .request({
      url: `${CONTRACT_REQUESTS}?isCompany=${isCompany ? "true" : "false"}`,
      method: "GET",
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      return []
    })
}

/**
 * Send requet to get contracts
 * @param {*} isCompany
 * @returns
 */
export const getContracts = isCompany => {
  return axiosInstance
    .request({
      url: `${CONTRACTS}?isCompany=${isCompany ? "true" : "false"}`,
      method: "GET",
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      return []
    })
}

/**
 * Send request to create a new A2A contract request
 * @param {*} user
 * @returns
 */
export const createContractRequest = payload => {
  return axiosInstance
    .request({
      url: CONTRACT_REQUESTS,
      method: "POST",
      data: payload,
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}

/**
 * Send request to get contract by id
 * @param {*} id
 * @returns
 */
export const getContractById = id => {
  return axiosInstance
    .request({
      url: `${CONTRACT_REQUESTS}/${id}`,
      method: "GET",
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}

/**
 * Send request to update contract request
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export const updateContract = (id, payload) => {
  return axiosInstance
    .request({
      url: `${CONTRACT_REQUESTS}/${id}${UPDATE}`,
      method: "PUT",
      data: payload,
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}

/**
 * Send request to sign contract
 * @param {*} id
 * @param {SIGNER} role
 * @returns
 */
export const signContract = (id, role) => {
  return axiosInstance
    .request({
      url: `${CONTRACT_REQUESTS}/${id}${SIGN}`,
      method: "PUT",
      data: { role },
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}

/**
 * Send request to reject contract
 * @param {*} id
 * @param {*} reason
 * @returns
 */
export const declineContract = (id, reason) => {
  return axiosInstance
    .request({
      url: `${CONTRACT_REQUESTS}/${id}${REJECT}`,
      method: "PUT",
      data: { reason },
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}
