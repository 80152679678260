import axios from "axios"

export const randomBackground = () => {
  const backgrounds = [
    "primary",
    "success",
    "warning",
    "danger",
    "info",
    "secondary",
    "dark",
  ]
  const index = Math.floor(Math.random() * 8)
  return backgrounds[index]
}

export const getExtension = filename => {
  const dotIndex = filename.lastIndexOf(".")
  return dotIndex !== -1 ? filename.slice(dotIndex + 1) : ""
}

export const fileUpload = (
  directory,
  fileName,
  file,
  onUploadProgressFn = () => {}
) => {
  return axios
    .request({
      url: `https://storage.bunnycdn.com/${process.env.REACT_APP_BUNNY_STORAGE_ZONE}/${directory}/${fileName}`,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": "application/octet-stream",
        AccessKey: process.env.REACT_APP_BUNNY_ACCESS_KEY,
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        onUploadProgressFn(percentCompleted)
      },
    })
    .then(
      () => `${process.env.REACT_APP_BUNNY_CDN_HOST}/${directory}/${fileName}`
    )
    .catch(e => {
      console.log(e.message)
      return e.message
    })
}
