import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import PropTypes from "prop-types"

//Import Images
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { USER_ROLE } from "constants/enum"

const CardUser = ({ summary }) => {
  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      {user.photoURL ? (
                        <img
                          src={user.photoURL}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      ) : (
                        <div className="avatar-md">
                          <span
                            className={`avatar-title rounded-circle bg-primary text-white font-size-24`}
                          >
                            {user.displayName.charAt(0)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome to Dashboard</p>
                        <h5 className="mb-1">{user.displayName}</h5>
                        <p className="mb-0 text-capitalize">
                          {user.role === USER_ROLE.COMPNAY
                            ? "Company"
                            : "Agent"}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="8" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row className="d-flex justify-content-between">
                      {summary.map((item, index) => (
                        <Col xs="3" key={index}>
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              {item.title}
                            </p>
                            <h5 className="mb-0">{item.value}</h5>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardUser.propTypes = {
  summary: PropTypes.array.isRequired,
}

export default CardUser
