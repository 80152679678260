import {
  GET_CONTRACTS,
  GET_CONTRACTS_FAIL,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACT_DETAIL,
  GET_CONTRACT_DETAIL_FAIL,
  GET_CONTRACT_DETAIL_SUCCESS,
} from "./actionTypes"

export const getContracts = () => ({
  type: GET_CONTRACTS,
})

export const getContractsSuccess = invoices => ({
  type: GET_CONTRACTS_SUCCESS,
  payload: invoices,
})

export const getContractsFail = error => ({
  type: GET_CONTRACTS_FAIL,
  payload: error,
})

export const getContractDetail = invoiceId => ({
  type: GET_CONTRACT_DETAIL,
  invoiceId,
})

export const getContractDetailSuccess = invoices => ({
  type: GET_CONTRACT_DETAIL_SUCCESS,
  payload: invoices,
})

export const getContractDetailFail = error => ({
  type: GET_CONTRACT_DETAIL_FAIL,
  payload: error,
})
