import Breadcrumb from "components/Common/Breadcrumb"
import React, { Fragment, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
} from "reactstrap"

// Import Editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import * as Yup from "yup"
import { useFormik } from "formik"

const ContactSupports = () => {
  //meta title
  document.title = "Contact Supports | ListProperty"

  // State for tickets and modal visibility
  const [tickets, setTickets] = useState([])
  const [modal, setModal] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [formKey, setFormKey] = useState(0)

  // Toggle modal
  const toggleModal = () => setModal(!modal)

  // View ticket details
  const viewTicket = ticket => {
    setSelectedTicket(ticket)
    toggleModal()
  }

  // validation
  const validation = useFormik({
    initialValues: {
      subject: "",
      description: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const newTicket = { ...values, id: tickets.length + 1, status: "Open" }
      setTickets([...tickets, newTicket])
      resetForm()
      setFormKey(formKey + 1)
    },
  })

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title="Help & Supports"
            breadcrumbItem="Contact Supports"
          />

          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <CardTitle>My Tickets</CardTitle>
                  <p>
                    This section displays all the support tickets you have
                    submitted. You can view each ticket's subject, current
                    status, and any additional details by clicking "View." Track
                    the progress of your inquiries here, and keep updated on
                    responses or resolutions from our support team.
                  </p>
                  <div className="table-responsive">
                    <Table className="table mb-0 border-start border-end">
                      <thead className="table-light">
                        <tr>
                          <th>#</th>
                          <th>Subject</th>
                          <th>Status</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tickets.map(ticket => (
                          <tr key={ticket.id}>
                            <td>{ticket.id}</td>
                            <td className="text-truncate">{ticket.subject}</td>
                            <td>
                              <span className="font-size-12 badge-soft-success badge bg-secondary">
                                {ticket.status}
                              </span>
                            </td>
                            <td className="text-center">
                              <Button
                                color="primary"
                                size="sm"
                                className="rounded-5"
                                onClick={() => viewTicket(ticket)}
                              >
                                <i className="mdi mdi-eye" /> View
                              </Button>
                            </td>
                          </tr>
                        ))}
                        {tickets.length === 0 && (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No tickets
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle>New Ticket</CardTitle>
                  <p>
                    If you're experiencing any issues or have questions, please
                    fill out the form below to reach our support team. Provide a
                    clear subject and detailed description of the issue, and
                    we'll get back to you as soon as possible.
                  </p>
                  <Form onSubmit={validation.handleSubmit} key={formKey}>
                    <FormGroup>
                      <Label className="form-label">Subject</Label>
                      <Input
                        name="subject"
                        className="form-control"
                        placeholder="Enter ticket subject"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        autoComplete="off"
                        invalid={
                          validation.touched.subject &&
                          validation.errors.subject
                            ? true
                            : false
                        }
                      />
                      {validation.touched.subject &&
                      validation.errors.subject ? (
                        <FormFeedback type="invalid">
                          {validation.errors.subject}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-label">Description</Label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={validation.values.description || ""}
                        config={{
                          placeholder: "Enter ticket description.",
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData()
                          validation.setFieldValue("description", data)
                        }}
                      />
                      {validation.touched.description &&
                      validation.errors.description ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <Button color="primary" type="submit">
                      Submit Ticket
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Modal for Viewing Ticket Details */}
          {selectedTicket && (
            <Modal isOpen={modal} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}>Ticket Details</ModalHeader>
              <ModalBody>
                <div className="mb-2">
                  <strong>Subject:</strong> {selectedTicket.subject}
                </div>
                <div className="my-2">
                  <div className="fw-bold">Description:</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedTicket.description,
                    }}
                  />
                </div>
                <div className="mt-2">
                  <strong>Status:</strong>{" "}
                  <span className="font-size-12 badge-soft-success badge bg-secondary">
                    {selectedTicket.status}
                  </span>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </Container>
      </div>
    </Fragment>
  )
}

export default ContactSupports
