import React from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CompanyDashboard from "./company-dashboard"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import AgentDashboard from "./agent-dashboard"
import { USER_ROLE } from "constants/enum"

const Dashboard = () => {
  //meta title
  document.title = "Dashboard | ListProperty"
  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Home" breadcrumbItem="Dashboard" />

          {user.role === USER_ROLE.COMPNAY ? (
            <CompanyDashboard />
          ) : (
            <AgentDashboard />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
