import {
  GET_CONTRACTS_FAIL,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACT_DETAIL_SUCCESS,
  GET_CONTRACT_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  contracts: [],
  contractDetail: {},
  error: {},
  loading: true,
}

const Contracts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.payload,
        loading: true,
      }

    case GET_CONTRACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTRACT_DETAIL_SUCCESS:
      return {
        ...state,
        contractDetail: action.payload,
      }

    case GET_CONTRACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Contracts
