import axiosInstance from "helpers/axios_helper"
import { CREATE_AGENT, GET_AGENTS } from "helpers/url_helper"

export const getAgents = () => {
  return axiosInstance
    .request({
      url: GET_AGENTS,
      method: "GET",
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      return []
    })
}

export const createAgent = user => {
  return axiosInstance
    .request({
      url: CREATE_AGENT,
      method: "POST",
      data: user,
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      return e.message
    })
}

export const updateAgent = (id, user) => {
  return axiosInstance
    .request({
      url: `${GET_AGENTS}/${id}`,
      method: "PUT",
      data: user,
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      return e.message
    })
}
