import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { map } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card contract
import CardContract from "./../card-contract"
import Spinners from "components/Common/Spinner"
import { toast } from "react-toastify"
import { getContractReqeusts } from "services/contractService"
import { USER_ROLE } from "constants/enum"

const ContractsList = () => {
  //meta title
  document.title = "Contract Requests List | ListProperty"

  const [isLoading, setLoading] = useState(false)
  const [contracts, setContracts] = useState([])

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)

  const init = async () => {
    setLoading(true)
    try {
      const res = await getContractReqeusts(user.role === USER_ROLE.COMPNAY)
      setContracts(res)
    } catch (e) {
      toast.error(e.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="A2A Contracts"
            breadcrumbItem="Contract Reqeusts"
          />

          <Card className="job-filter">
            <CardBody>
              <Form>
                <Row className="g-3">
                  <Col xl={8} lg={6}>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        id="searchJob"
                        placeholder="Search contract requests"
                      />
                    </div>
                  </Col>

                  <Col xl={2} lg={3}>
                    <div className="position-relative">
                      <select className="form-control">
                        <option>Status</option>
                        <option>Pending</option>
                        <option>Accepted</option>
                        <option>Declined</option>
                      </select>
                    </div>
                  </Col>

                  <Col xl={2} lg={3}>
                    <div className="position-relative h-100 hstack gap-3">
                      <button
                        type="button"
                        className="btn btn-primary h-100 w-100"
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                        Find Requests
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <>
              <Row>
                {(contracts || [])?.map((contract, key) => (
                  <CardContract
                    data={contract}
                    key={"_invoice_" + key}
                    status="Pending"
                    link={`/contracts/requests/${contract.id}/edit`}
                  />
                ))}
              </Row>
              <Row>
                <Col xs="12">
                  <div className="text-center my-3">
                    {/* <Link to="#" className="text-success">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                      Load more
                    </Link> */}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ContractsList.propTypes = {
  contracts: PropTypes.array,
  onGetContracts: PropTypes.func,
}

export default withRouter(ContractsList)
