import React, { useEffect, useState } from "react"
import Spinners from "components/Common/Spinner"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"

const PropertyList = ({ properties, loading, isCompany, editable }) => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(loading)
  }, [loading])
  return (
    <>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <Row>
            {(properties || []).map((item, key) => (
              <Col xxl={4} lg={6} key={key}>
                <Card className="shadow-lg">
                  <CardBody className="p-0">
                    <div className="position-relative">
                      <img
                        className="img img-fluid rounded-top w-100"
                        src={item.images[0]}
                      />
                      <div className="position-absolute top-0 ps-2">
                        <div className="rounded-3 text-capitalize text-white bg-primary fs-6 px-2 my-2">
                          <i className="bx bx-purchase-tag me-1" />
                          {item.status.replace("_", " ")}
                        </div>
                        {item.furnished && (
                          <div className="rounded-3 text-white text-capitalize bg-success fs-6 px-2 my-2">
                            <i className="bx bx-gift me-1" />
                            {item.furnished.replace("_", " ")}
                          </div>
                        )}
                      </div>
                      <div className="position-absolute start-0 bottom-0 p-2">
                        <Button className="btn-rounded btn bg-transparent border-danger me-2 px-2 py-1">
                          <i className="mdi mdi-heart text-danger" />
                        </Button>
                      </div>
                      {isCompany && editable && (
                        <div className="position-absolute end-0 bottom-0 p-2">
                          <Link
                            to={`/properties/${item.id}/edit`}
                            className="btn-rounded btn px-2 py-1 btn-secondary me-2"
                          >
                            <i className="bx bx-pencil" />
                          </Link>
                          <Button className="btn-rounded btn px-2 py-1 btn-danger">
                            <i className="bx bx-trash" />
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="p-3">
                      <Link to={`/properties/${item.id}`} className="text-dark">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="mb-1 text-primary font-size-14">
                              {item.property_type.title}
                            </p>
                            <h3 className="text-capitalize">
                              {item.price[0].value} AED
                            </h3>
                            <p className="fs-6 amendity-list">
                              {item.Amendity &&
                                item.Amendity.map((item, index) => (
                                  <span key={index} className="amendity">
                                    {item.title}
                                  </span>
                                ))}
                            </p>
                          </div>
                          {item.agent.avatar ? (
                            <img
                              className="avatar-md rounded-circle img img-thumbnail"
                              src={item.agent.avatar}
                            />
                          ) : (
                            <div className="avatar-md">
                              <span className="avatar-title rounded-circle bg-primary text-white fs-3">
                                {item.agent.full_name.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center mt-3 text-muted">
                          <i className="bx bx-map me-1 fs-4" />
                          {item.location[0].address}
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                          <div className="d-flex align-items-center">
                            <i className="bx bx-bed me-1 fs-5" />{" "}
                            {item.bedrooms} Bedrooms
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="bx bx-bath me-1 fs-5" />{" "}
                            {item.bathrooms} Bathrooms
                          </div>
                          <div className="d-flex align-items-center">
                            <i className="mdi mdi-vector-square me-1 fs-5" />
                            {item.size} sqft
                          </div>
                        </div>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs="12">
              {/* <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin me-2" />
                  Load more
                </Link>
              </div> */}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default PropertyList
