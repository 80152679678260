import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"
import moment from "moment"
import { toast } from "react-toastify"
import withRouter from "components/Common/withRouter"
import Breadcrumb from "components/Common/Breadcrumb"
import { getContractById, updateContract } from "services/contractService"
import { Link } from "react-router-dom"

const ContractEditOwner = props => {
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState({})
  const [history, setHistory] = useState([])

  const goBack = e => {
    e.preventDefault()
    props.router.navigate(-1)
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      seller_commission: Number(detail.seller_commission) || "",
      buyer_commission: Number(detail.buyer_commission) || "",
      fee_paid_by: detail.fee_paid_by || [true, true, false],
      pre_finance: detail.pre_finance || false,
      listing_agent: detail.listing_agent || false,
      budget: Number(detail.budget) || "",
      termsAcceptance: false,
    },
    validationSchema: Yup.object({
      seller_commission: Yup.number().required("Seller commission is required"),
      buyer_commission: Yup.number().required("Buyer commission is required"),
      budget: Yup.number().required("budget is required"),
      termsAcceptance: Yup.boolean()
        .oneOf([true], "You must accept the terms and conditions.")
        .required("You must accept the terms and conditions."),
    }),
    onSubmit: async values => {
      let payload = { ...values }
      delete payload.termsAcceptance

      try {
        setLoading(true)
        await updateContract(props.router.params.id, payload)
        toast.success("The contract request has been updated.")
        props.router.navigate("/contracts/requests")
        setLoading(false)
      } catch (e) {
        toast.error(e.message)
      }
    },
  })

  const init = async id => {
    try {
      setLoading(true)
      const res = await getContractById(id)
      setDetail(res)
      setHistory(res.History.reverse())
      setLoading(false)
    } catch (e) {
      toast.error(e.message)
    }
  }

  useEffect(() => {
    init(props.router.params.id)
  }, [props.router.params.id])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Contract Requests" breadcrumbItem="Edit Request" />

          <Row>
            <Col xxl={9} xl={8}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Row>
                      <Col className="fs-5 fw-semibold text-uppercase">
                        Property
                        <hr className="mt-1 mb-2 border-dark" />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6}>
                        <Row className="my-1">
                          <Col xl={4} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Property Address:
                            </Label>
                          </Col>
                          <Col xl={8}>
                            {detail?.property?.location[0]?.address}
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6}>
                        <Row className="my-1">
                          <Col xl={4} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Master developer:
                            </Label>
                          </Col>
                          <Col xl={8}>{detail?.property?.developer}</Col>
                        </Row>
                      </Col>
                      <Col xl={6}>
                        <Row className="my-1">
                          <Col xl={4} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Master project name:
                            </Label>
                          </Col>
                          <Col xl={8}>{detail?.property?.project_name}</Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col sm={12} className="text-uppercase fs-6 fw-semibold">
                        Property details
                      </Col>
                      <Col sm={12} className="text-capitalize">
                        Listing agent to complete
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6}>
                        <Row className="my-1">
                          <Col xl={4} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Building name:
                            </Label>
                          </Col>
                          <Col xl={8}>{detail?.property?.reference}</Col>
                        </Row>
                      </Col>
                      <Col xl={6}>
                        <Row className="my-1">
                          <Col xl={4} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Listed price:
                            </Label>
                          </Col>
                          <Col xl={8}>
                            AED {detail?.property?.price[0].value}
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row className="my-1">
                          <Col xl={2} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Description:
                            </Label>
                          </Col>
                          <Col xl={10}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: detail?.property?.description,
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row className="my-1">
                          <Col xl={3} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Does a mou exist on this property?
                            </Label>
                          </Col>
                          <Col xl={9}>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="mou"
                                defaultChecked
                                readOnly
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="mou"
                                readOnly
                              />
                              <label className="form-check-label">No</label>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row className="my-1">
                          <Col xl={3} className="text-stard text-lg-end">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Is the property tenanted?
                            </Label>
                          </Col>
                          <Col xl={9}>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="tenanted"
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="tenanted"
                                defaultChecked
                                readOnly
                              />
                              <label className="form-check-label">No</label>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <Form onSubmit={validation.handleSubmit}>
                    <Row className="mt-5">
                      <Col xl={12} className="fs-5 fw-semibold text-uppercase">
                        The Commission{" "}
                        <span className="text-lowercase">(split)</span>
                        <hr className="mt-1 mb-2 border-dark" />
                      </Col>
                      <Col xl={12}>
                        The following commission split is agreed between the
                        Seller's Agent & Buyer's Agent.
                      </Col>
                      <Col xl={6}>
                        <Row className="my-2">
                          <Col sm={4} className="text-uppercase">
                            <Label className="col-form-label">
                              Seller's agent
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <InputGroup>
                              <Input
                                type="number"
                                step={0.1}
                                name="seller_commission"
                                value={validation.values.seller_commission}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.touched.seller_commission &&
                                  validation.errors.seller_commission
                                    ? true
                                    : false
                                }
                              />
                              <InputGroupText>%</InputGroupText>
                            </InputGroup>
                            {validation.touched.seller_commission &&
                            validation.errors.seller_commission ? (
                              <FormFeedback type="invalid" className="d-block">
                                {validation.errors.seller_commission}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6}>
                        <Row className="my-2">
                          <Col sm={4} className="text-uppercase">
                            <Label className="col-form-label">
                              Buyer's agent
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <InputGroup>
                              <Input
                                type="number"
                                step={0.1}
                                name="buyer_commission"
                                typeof="number"
                                value={validation.values.buyer_commission}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.touched.buyer_commission &&
                                  validation.errors.buyer_commission
                                    ? true
                                    : false
                                }
                              />
                              <InputGroupText>%</InputGroupText>
                            </InputGroup>
                            {validation.touched.buyer_commission &&
                            validation.errors.buyer_commission ? (
                              <FormFeedback type="invalid" className="d-block">
                                {validation.errors.buyer_commission}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row className="my-2">
                          <Col sm={2} className="text-uppercase">
                            <Label className="">Buyer's name:</Label>
                          </Col>
                          <Col sm={10}>{detail?.buyer?.full_name}</Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row className="my-2">
                          <Col sm={2} className="text-uppercase">
                            <Label className="">Transfer fee paid by:</Label>
                          </Col>
                          <Col sm={10}>
                            <div className="form-check form-check-inline">
                              <label className="form-check-label text-uppercase">
                                Seller
                              </label>
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                name="fee_paid_by"
                                onChange={e =>
                                  validation.setFieldValue("fee_paid_by", [
                                    e.target.checked,
                                    validation.values.fee_paid_by[1],
                                    validation.values.fee_paid_by[2],
                                  ])
                                }
                                defaultChecked={
                                  validation.values.fee_paid_by[0]
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values.fee_paid_by[0] || ""}
                              />
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <label className="form-check-label text-uppercase">
                                Buyer
                              </label>
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                name="fee_paid_by"
                                defaultChecked={
                                  validation.values.fee_paid_by[1]
                                }
                                onChange={e =>
                                  validation.setFieldValue("fee_paid_by", [
                                    validation.values.fee_paid_by[0],
                                    e.target.checked,
                                    validation.values.fee_paid_by[2],
                                  ])
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values.fee_paid_by[1] || ""}
                              />
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <label className="form-check-label text-uppercase">
                                NEG
                              </label>
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                name="fee_paid_by"
                                defaultChecked={
                                  validation.values.fee_paid_by[2]
                                }
                                onChange={e =>
                                  validation.setFieldValue("fee_paid_by", [
                                    validation.values.fee_paid_by[0],
                                    validation.values.fee_paid_by[1],
                                    e.target.checked,
                                  ])
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values.fee_paid_by[2] || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row className="my-2">
                          <Col sm={2} className="text-uppercase">
                            <Label className="col-form-label">budget:</Label>
                          </Col>
                          <Col lg={4} sm={12}>
                            <InputGroup>
                              <Input
                                name="budget"
                                type="number"
                                value={validation.values.budget}
                                onChange={validation.handleChange}
                                invalid={
                                  validation.touched.budget &&
                                  validation.errors.budget
                                    ? true
                                    : false
                                }
                              />
                              <InputGroupText>AED</InputGroupText>
                            </InputGroup>
                            {validation.touched.budget &&
                            validation.errors.budget ? (
                              <FormFeedback type="invalid" className="d-block">
                                {validation.errors.budget}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={12}>
                        <Row className="my-2">
                          <Col xl={4} className="">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Has the buyer had pre-finance approval?
                            </Label>
                          </Col>
                          <Col xl={8}>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="pre_finance"
                                defaultChecked={validation.values.pre_finance}
                                onChange={e =>
                                  validation.setFieldValue(
                                    "pre_finance",
                                    e.target.checked
                                  )
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values.pre_finance || ""}
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="pre_finance"
                                defaultChecked={!validation.values.pre_finance}
                                onChange={e =>
                                  validation.setFieldValue(
                                    "pre_finance",
                                    !e.target.checked
                                  )
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values.pre_finance || ""}
                              />
                              <label className="form-check-label">No</label>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={12}>
                        <Row className="my-2">
                          <Col xl={4} className="">
                            <Label className="horizontal-form-label text-uppercase mb-0">
                              Has this buyer contacted the listing agent?
                            </Label>
                          </Col>
                          <Col xl={8}>
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="listing_agent"
                                defaultChecked={validation.values.listing_agent}
                                onChange={e =>
                                  validation.setFieldValue(
                                    "listing_agent",
                                    e.target.checked
                                  )
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values.listing_agent || ""}
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="listing_agent"
                                defaultChecked={
                                  !validation.values.listing_agent
                                }
                                onChange={e =>
                                  validation.setFieldValue(
                                    "listing_agent",
                                    !e.target.checked
                                  )
                                }
                                onBlur={validation.handleBlur}
                                value={validation.values.listing_agent || ""}
                              />
                              <label className="form-check-label">No</label>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col xl={12} className="my-3">
                        <div className="d-flex">
                          <Input
                            name="termsAcceptance"
                            type="checkbox"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.termsAcceptance || ""}
                            invalid={
                              validation.touched.termsAcceptance &&
                              validation.errors.termsAcceptance
                                ? true
                                : false
                            }
                          />
                          <Label className="form-label ms-1 mb-1 fst-italic">
                            I hereby declare, I have read and understood the
                            Real Estate Broker Code of Ethics, I have a current
                            signed Seller's Agreement FORM A, I shall respond to
                            a reasonable offer to purchase the listed property
                            from Agent B, and shall not contact Agent B's Buyer
                            nor confer with their client under no circumstances
                            unless the nominated Buyer herein has already
                            discussed the stated listed property with our
                            Office.
                          </Label>
                        </div>
                        {validation.touched.termsAcceptance &&
                        validation.errors.termsAcceptance ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.termsAcceptance}
                          </FormFeedback>
                        ) : null}
                      </Col>

                      <Col xl={12}>
                        <Link
                          to="#"
                          onClick={goBack}
                          className="btn btn-primary"
                        >
                          <i className="mdi mdi-arrow-left" /> Back
                        </Link>
                        <button
                          disabled={loading}
                          type="submit"
                          className="btn btn-success ms-2"
                        >
                          <i className="bx bx-edit-alt" />{" "}
                          {loading ? "Submitting..." : "Update"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xxl={3} xl={4}>
              <Card>
                <CardBody>
                  <CardTitle>Activities</CardTitle>
                  <ul className="verti-timeline list-unstyled mt-3">
                    {(history || []).map((event, index) => (
                      <li className={`event-list`} key={index}>
                        <div className="event-timeline-dot">
                          <i className="bx bx-right-arrow-circle font-size-18 text-primary"></i>
                        </div>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14 mb-0">
                              {moment(event.created_at).format("MMM DD")}{" "}
                              <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                            </h5>
                          </div>
                          <div className="flex-grow-1">
                            <div className="">
                              <span className="fw-semibold">
                                {" "}
                                {event.user.full_name}
                              </span>{" "}
                              {event.status} the A2A contract request.
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContractEditOwner)
