import axiosInstance from "helpers/axios_helper"
import { PROPERTIES, PROPERTY_TYPES } from "helpers/url_helper"

/**
 * Send request to get properties
 * @returns
 */
export const getProperties = (isCompany, isAll = false) => {
  return axiosInstance
    .request({
      url: `${PROPERTIES}?isCompany=${isCompany ? "true" : "false"}&all=${
        isAll ? "true" : "false"
      }`,
      method: "GET",
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      return []
    })
}

/**
 * Get request to get property detail
 * @param {*} id
 * @returns
 */
export const getPropertyById = id => {
  return axiosInstance
    .request({
      url: `${PROPERTIES}/${id}`,
      method: "GET",
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}

/**
 * Send request to create a property
 * @param {*} property
 * @returns
 */
export const createProperty = property => {
  return axiosInstance
    .request({
      url: PROPERTIES,
      method: "POST",
      data: property,
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}

/**
 * Send request to edit property
 * @param {*} property
 * @returns
 */
export const editProperty = (id, property) => {
  return axiosInstance
    .request({
      url: `${PROPERTIES}/${id}`,
      method: "PUT",
      data: property,
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      throw e
    })
}

/**
 * Send request to get property types
 * @returns
 */
export const getPropertyTypes = () => {
  return axiosInstance
    .request({
      url: PROPERTY_TYPES,
      method: "GET",
    })
    .then(res => res.data)
    .catch(e => {
      console.log(e.message)
      return []
    })
}
