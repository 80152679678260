import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import CardContract from "./../card-contract"
import Spinners from "components/Common/Spinner"
import Breadcrumb from "components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { getContracts } from "services/contractService"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { USER_ROLE } from "constants/enum"

const ContractsList = () => {
  //meta title
  document.title = "Contracts List | ListProperty"

  const [isLoading, setLoading] = useState(false)
  const [contracts, setContracts] = useState([])

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)

  const init = async () => {
    setLoading(true)
    try {
      const res = await getContracts(user.role === USER_ROLE.COMPNAY)
      setContracts(res)
    } catch (e) {
      toast.error(e.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="A2A Contracts" breadcrumbItem="Contracts" />

          <Card className="job-filter">
            <CardBody>
              <Form>
                <Row className="g-3">
                  <Col xl={8} lg={6}>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        id="searchJob"
                        placeholder="Search contracts"
                      />
                    </div>
                  </Col>

                  <Col xl={2} lg={3}>
                    <div className="position-relative">
                      <select className="form-control">
                        <option>Status</option>
                        <option>Active</option>
                        <option>Completed</option>
                        <option>Archieved</option>
                      </select>
                    </div>
                  </Col>

                  <Col xl={2} lg={3}>
                    <div className="position-relative h-100 hstack gap-3">
                      <button
                        type="button"
                        className="btn btn-primary h-100 w-100"
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                        Find Contracts
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <>
              <Row>
                {(contracts || [])?.map((contract, key) => (
                  <CardContract
                    data={contract}
                    key={"_invoice_" + key}
                    status="Active"
                    link={`/contracts/${contract.id}`}
                  />
                ))}
              </Row>
              <Row>
                <Col xs="12">
                  <div className="text-center my-3">
                    {/* <Link to="#" className="text-success">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                      Load more
                    </Link> */}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ContractsList.propTypes = {
  contracts: PropTypes.array,
  onGetContracts: PropTypes.func,
}

export default withRouter(ContractsList)
