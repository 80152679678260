import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { createSelector } from "reselect"

const Authmiddleware = props => {
  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }

  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
