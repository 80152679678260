import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap"

//Import Image
import moment from "moment"
import { randomBackground } from "helpers/utils"

const CardInvoice = ({ data, link }) => {
  return (
    <React.Fragment>
      <Col xxl="4" sm="6">
        <Link to={link}>
          <Card>
            <CardBody>
              <p className="text-truncate mt-2 mb-1">{data?.note}</p>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="text-center">
                    {data?.seller?.avatar ? (
                      <img
                        src={data?.seller?.avatar}
                        className="avatar-sm mx-auto mb-2 mt-1 rounded-circle"
                        alt="img"
                      />
                    ) : (
                      <div className="avatar-sm mx-auto mb-2 mt-1 rounded-circle">
                        <span
                          className={`avatar-title rounded-circle bg-${randomBackground()} font-size-16`}
                        >
                          {data?.seller?.full_name?.charAt(0)}
                        </span>
                      </div>
                    )}

                    <div className="d-none d-sm-block">
                      <h5 className="mb-0 font-size-15 text-truncate">
                        {data?.seller?.full_name}
                      </h5>
                      <div className="text-muted">
                        {data?.seller?.company?.company_name}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <i className="bx bx-transfer fs-1 text-primary" />
                </div>

                <div>
                  <div className="text-lg-center">
                    {data?.buyer?.avatar ? (
                      <img
                        src={data?.buyer?.avatar}
                        className="avatar-sm mx-auto mb-2 mt-1 rounded-circle"
                        alt="img"
                      />
                    ) : (
                      <div className="avatar-sm mx-auto mb-2 mt-1 rounded-circle">
                        <span
                          className={`avatar-title rounded-circle bg-${randomBackground()} font-size-16`}
                        >
                          {data?.buyer?.full_name?.charAt(0)}
                        </span>
                      </div>
                    )}

                    <div className="d-none d-sm-block">
                      <h5 className="mb-0 font-size-15 text-truncate">
                        {data?.buyer?.full_name}
                      </h5>
                      <div className="text-muted">
                        {data?.buyer?.company?.company_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
            <CardFooter className="d-flex bg-white justify-content-between align-items-center border-top">
              <span className="badge text-capitalize bg-primary font-size-12">
                {data?.status.replace("_", " ")}
              </span>
              <div className="fs-sm">
                <i className="bx bx-calendar me-1" />
                {moment(data?.updated_at).format("MMM DD, YYYY")}
              </div>
              <div className="avatar-group">
                {data.reviewers.map((team, key) =>
                  team?.avatar ? (
                    <React.Fragment key={key}>
                      <div className="avatar-group-item">
                        <div
                          to="#"
                          className="d-inline-block"
                          id={"member" + team?.id}
                        >
                          <img
                            src={team?.avatar}
                            className="rounded-circle avatar-xs"
                            alt=""
                          />

                          <UncontrolledTooltip
                            placement="top"
                            target={"member" + team?.id}
                          >
                            Accepted by {team?.fullName}
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={key}>
                      <div className="avatar-group-item">
                        <div
                          to="#"
                          className="d-inline-block"
                          id={"member" + team?.id}
                        >
                          <div className="avatar-xs">
                            <span
                              className={`avatar-title rounded-circle bg-${randomBackground()} text-white font-size-16`}
                            >
                              {team?.full_name.charAt(0)}{" "}
                            </span>
                            <UncontrolledTooltip
                              placement="top"
                              target={"member" + team?.id}
                            >
                              Approved by {team?.full_name}
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                )}
              </div>
            </CardFooter>
          </Card>
        </Link>
      </Col>
    </React.Fragment>
  )
}

CardInvoice.propTypes = {
  data: PropTypes.any,
}

export default CardInvoice
