import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Agents
import AgentsGrid from "../pages/Agents"

// Chats
import Chat from "../pages/Chat/Chat"

// Properties
import PropertyGrid from "../pages/Properties/PropertyList/index"
import PropertyDetails from "../pages/Properties/PropertyDetail"
import PropertyCreate from "../pages/Properties/property-create"
import SearchProperty from "../pages/Properties/PropertyList/SearchProperty"
import PropertyEdit from "../pages/Properties/property-edit"

// FAQ
import FAQ from "../pages/Helps-Supports/pages-faqs"

// support
import Support from "pages/Helps-Supports/contact-supports"

// Contracts
import ContractsList from "../pages/Contracts/list/contracts-list"
import ContractDetails from "../pages/Contracts/list/contracts-detail"
import ContractRequestsList from "../pages/Contracts/requests/contracts-list"
import ContractRequestDetails from "../pages/Contracts/requests/contracts-detail"
import ContractRequestEdit from "../pages/Contracts/requests/contract-edit"
import ContractRequestOwner from "../pages/Contracts/requests/contract-edit/contract-edit-owner"

// Settings
import SettingsPage from "pages/Settings"

// Favorites
import Favorites from "../pages/Favorites"

// Notifications
import NotificationsPage from "pages/Notifications"

// Terms and conditions
import TermsAndConditions from "pages/Terms"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  //profile
  { path: "/profile", component: <UserProfile /> },

  // Contacts
  { path: "/agents", component: <AgentsGrid /> },

  //chat
  { path: "/chats", component: <Chat /> },

  //properties
  { path: "/properties", component: <PropertyGrid />, exact: true },
  { path: "/properties/create", component: <PropertyCreate />, exact: true },
  { path: "/properties/search", component: <SearchProperty />, exact: true },
  { path: "/properties/:id/edit", component: <PropertyEdit />, exact: true },
  { path: "/properties/:id", component: <PropertyDetails />, exact: true },

  //contacts
  { path: "/contracts", component: <ContractsList />, exact: true },
  {
    path: "/contracts/requests",
    component: <ContractRequestsList />,
    exact: true,
  },
  {
    path: "/contracts/requests/:id",
    component: <ContractRequestDetails />,
    exact: true,
  },
  {
    path: "/contracts/requests/:id/edit",
    component: <ContractRequestEdit />,
    exact: true,
  },
  {
    path: "/contracts/requests/:id/edit-by-company",
    component: <ContractRequestOwner />,
    exact: true,
  },
  { path: "/contracts/:id", component: <ContractDetails />, exact: true },

  // favorite
  { path: "/favorites", component: <Favorites />, exact: true },

  // faq
  { path: "/help-supports/faq", component: <FAQ />, exact: true },

  // supports
  { path: "/help-supports/supports", component: <Support />, exact: true },

  // settings
  { path: "/settings", component: <SettingsPage />, exact: true },

  // notifications
  { path: "/notifications", component: <NotificationsPage />, exact: true },

  // terms
  { path: "/terms", component: <TermsAndConditions />, exact: true },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
