import React from "react"
import { Row, Col, Button, Label, Input, FormFeedback, Form } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import moment from "moment"

// FlatPickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"

const AgentProfile = () => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      title: "",
      phone: "",
      email: "",
      password: "",
      dob: "",
      license: "",
      emirate: "",
      avatar: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Full nameme is required")
        .max(100, "Full name must be at most 100 characters"),
      title: Yup.string()
        .required("Position/Title is required")
        .max(50, "Position/Title must be at most 50 characters"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email format is invalid.")
        .required("Email address is required"),
      phone: Yup.string()
        .required("Contact Number is required")
        .matches(/^\d{10}$/, "Contact Number must be 10 digits"),
      license: Yup.string().required("Real estate license is required."),
      dob: Yup.string().required("Date of birth is required."),
      emirate: Yup.string().required("Emirate ID is required."),
    }),
    onSubmit: values => {},
  })

  return (
    <React.Fragment>
      <Form onSubmit={validation.handleSubmit}>
        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Full Name</Label>
          <Col sm={9}>
            <Input
              name="name"
              type="text"
              placeholder="Enter full name."
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.name || ""}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Position/Title</Label>
          <Col sm={9}>
            <Input
              name="title"
              label="Position/Title"
              placeholder="Enter position/title."
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.title || ""}
              invalid={
                validation.touched.title && validation.errors.title
                  ? true
                  : false
              }
            />
            {validation.touched.title && validation.errors.title ? (
              <FormFeedback type="invalid">
                {validation.errors.title}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Email Address</Label>
          <Col sm={9}>
            <Input
              name="email"
              label="Email Address"
              type="email"
              placeholder="Enter email address."
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Contact Number</Label>
          <Col sm={9}>
            <Input
              name="phone"
              label="Contact Number"
              type="text"
              placeholder="Enter contact number."
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.phone || ""}
              invalid={
                validation.touched.phone && validation.errors.phone
                  ? true
                  : false
              }
            />
            {validation.touched.phone && validation.errors.phone ? (
              <FormFeedback type="invalid">
                {validation.errors.phone}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Date of Birth</Label>
          <Col sm={9}>
            <FlatPickr
              className="form-control d-block"
              id="dob"
              name="dob"
              placeholder="Select Date of Birth"
              options={{
                mode: "single",
                dateFormat: "d M, Y",
                maxDate: "today",
              }}
              onChange={customerdate =>
                validation.setFieldValue(
                  "dob",
                  moment(customerdate[0]).format("DD MMMM ,YYYY")
                )
              }
              value={validation.values.dob || ""}
            />
            {validation.errors.dob && validation.touched.dob ? (
              <FormFeedback type="invalid" className="d-block">
                {validation.errors.dob}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Real Estate License</Label>
          <Col sm={9}>
            <Input
              name="license"
              label="Real Estate License"
              type="text"
              placeholder="Enter real estate license."
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.license || ""}
              invalid={
                validation.touched.license && validation.errors.license
                  ? true
                  : false
              }
            />
            {validation.touched.license && validation.errors.license ? (
              <FormFeedback type="invalid">
                {validation.errors.license}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Emirate ID</Label>
          <Col sm={9}>
            <Input
              name="emirate"
              label="Emirate ID"
              type="text"
              placeholder="Enter emirate ID."
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.emirate || ""}
              invalid={
                validation.touched.emirate && validation.errors.emirate
                  ? true
                  : false
              }
            />
            {validation.touched.emirate && validation.errors.emirate ? (
              <FormFeedback type="invalid">
                {validation.errors.emirate}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="justify-content-end">
          <Col sm={9}>
            <Button type="submit" color="primary" className="w-md">
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default AgentProfile
