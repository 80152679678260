import PropTypes from "prop-types"
import React from "react"
import { Button, Card, CardBody, Col } from "reactstrap"
import moment from "moment"

const CardContact = props => {
  const { user, onEdit, onRemove } = props

  return (
    <React.Fragment>
      <Col xxl={3} xl={4} sm={6}>
        <Card>
          <CardBody>
            <div className="text-center">
              {user?.avatar ? (
                <img
                  src={user.avatar}
                  alt=""
                  className="mx-auto avatar-lg rounded-circle d-block"
                />
              ) : (
                <div className="avatar-lg mx-auto">
                  <span
                    className={
                      "avatar-title rounded-circle bg-primary" + " display-6"
                    }
                  >
                    {user?.full_name.charAt(0)}
                  </span>
                </div>
              )}
              <h5 className="mt-3 mb-1">{user?.full_name}</h5>
              <p className="text-muted mb-0">{user?.title}</p>
            </div>

            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="bx bx-phone text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Contact Number</h6>
                    <p className="text-muted fs-14 mb-0">
                      {user?.contact_number}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-mail-send text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email Address</h6>
                    <p className="text-muted fs-14 mb-0">{user?.email}</p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-calendar text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Date of Birth</h6>
                    <p className="text-muted fs-14 mb-0">
                      {moment(user?.dob).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-check-shield text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Real Estate License</h6>
                    <p className="text-muted fs-14 text-break mb-0">
                      {user?.license_id}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-hash text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Emirate ID</h6>
                    <p className="text-muted fs-14 mb-0">{user?.emirate_id}</p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4 hstack gap-2">
              <Button
                className="btn btn-soft-primary btn-hover rounded w-100"
                onClick={() => onEdit(user)}
              >
                <i className="mdi mdi-pencil"></i> Edit
              </Button>
              <Button
                className="btn btn-soft-danger btn-hover rounded w-100"
                onClick={onRemove}
              >
                <i className="mdi mdi-delete"></i> Remove
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardContact.propTypes = {
  user: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
}

export default CardContact
