import React from "react"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import PropertyList from "pages/Properties/PropertyList/PropertyList"

const JobGrid = () => {
  document.title = "My Favorites | ListProperty"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="My Favorites" />
          <PropertyList />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default JobGrid
