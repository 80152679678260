import Breadcrumb from "components/Common/Breadcrumb"
import React, { Fragment, useRef } from "react"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"
import SignaturePad from "react-signature-pad-wrapper"

const Settings = () => {
  //meta title
  document.title = "Settings | ListProperty"

  const signaturePadRef = useRef(null)

  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Home" breadcrumbItem="Settings" />

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Privacy Settings</CardTitle>
                  <div className="mt-3">
                    <div className="form-check form-switch form-switch-md mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked
                      />
                      <label className="form-check-label">
                        Visible Profile
                      </label>
                    </div>
                    <div className="form-check form-switch form-switch-md mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked
                      />
                      <label className="form-check-label">
                        Visible Listing
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Notification Preference</CardTitle>
                  <div className="mt-3">
                    <div className="form-check form-switch form-switch-md mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked
                      />
                      <label className="form-check-label">
                        Send Email Notification
                      </label>
                    </div>
                    <div className="form-check form-switch form-switch-md mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked
                      />
                      <label className="form-check-label">
                        Send SMS Notification
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Language Preference</CardTitle>
                  <select className="form-control mt-4">
                    <option>English</option>
                    <option>Arabic</option>
                    <option>Hindi</option>
                    <option>Russia</option>
                  </select>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Data Sharing Preference</CardTitle>
                  <div className="mt-3">
                    <div className="form-check form-switch form-switch-md mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked
                      />
                      <label className="form-check-label d-md-inline d-block">
                        Allowing data to be shared to personalize ads or
                        recommendations
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Linked Accounts</CardTitle>
                  <div className="my-2 d-flex align-items-center justify-content-between">
                    <div className="font-size-18">
                      <i className="mdi mdi-facebook text-primary" /> Facebook
                    </div>
                    <button className="btn btn-primary">Connect</button>
                  </div>
                  <div className="my-2 d-flex align-items-center justify-content-between">
                    <div className="font-size-18">
                      <i className="mdi mdi-twitter text-primary" /> Twitter
                    </div>
                    <button className="btn btn-primary">Connect</button>
                  </div>
                  <div className="my-2 d-flex align-items-center justify-content-between">
                    <div className="font-size-18">
                      <i className="mdi mdi-instagram text-primary" /> Instagram
                    </div>
                    <button className="btn btn-primary">Connect</button>
                  </div>
                  <div className="my-2 d-flex align-items-center justify-content-between">
                    <div className="font-size-18">
                      <i className="mdi mdi-linkedin text-primary" /> Linkedin
                    </div>
                    <button className="btn btn-primary">Connect</button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>Update signature</CardTitle>
                  <div
                    className="border position-relative"
                    style={{ height: "150px" }}
                  >
                    <SignaturePad ref={signaturePadRef} redrawOnResize />
                    <div className="position-absolute top-0 end-0">
                      <button
                        className="btn btn-sm btn-soft-danger"
                        onClick={() => signaturePadRef.current.clear()}
                      >
                        <i className="mdi mdi-close" /> Clear Signature
                      </button>
                    </div>
                  </div>
                  <button className="btn btn-primary mt-2">
                    <i className="bx bx-edit-alt" /> Save Signature
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default Settings
