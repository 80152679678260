import React from "react"
import { Row, Col, Button, Label, Input, FormFeedback, Form } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

const CompanyProfile = () => {
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: "",
      tradeLicenseNumber: "",
      businessRegistrationNumber: "",
      address: "",
      email: "",
      referralCode: "",
      password: "",
      retypePassword: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .required("Company Name is required")
        .max(100, "Company Name must be at most 100 characters"),
      tradeLicenseNumber: Yup.string()
        .required("Trade License Number is required")
        .matches(/^[A-Za-z0-9]+$/, "Trade License Number format is invalid"),
      businessRegistrationNumber: Yup.string()
        .required("Business Registration Number is required")
        .matches(
          /^[A-Za-z0-9]+$/,
          "Business Registration Number format is invalid"
        ),
      address: Yup.string()
        .required("Company Address is required")
        .max(200, "Company Address must be at most 200 characters"),
      contactNumber: Yup.string()
        .required("Contact Number is required")
        .matches(/^\d{10}$/, "Contact Number must be 10 digits"),
      email: Yup.string()
        .required("Email Address is required")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Email format is invalid."
        ),
    }),
    onSubmit: values => {},
  })

  return (
    <React.Fragment>
      <Form onSubmit={validation.handleSubmit}>
        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Company Name</Label>
          <Col sm={9}>
            <Input
              name="companyName"
              type="text"
              className="form-control"
              placeholder="Enter company name."
              value={validation.values.firstname}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.firstname && validation.errors.firstname
                  ? true
                  : false
              }
            />
            {validation.errors.firstname && validation.touched.firstname ? (
              <FormFeedback type="invalid">
                {validation.errors.firstname}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">
            Trade License Number
          </Label>
          <Col sm={9}>
            <Input
              name="tradeLicenseNumber"
              type="text"
              placeholder="Enter trade license number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.tradeLicenseNumber || ""}
              invalid={
                validation.touched.tradeLicenseNumber &&
                validation.errors.tradeLicenseNumber
                  ? true
                  : false
              }
            />
            {validation.touched.tradeLicenseNumber &&
            validation.errors.tradeLicenseNumber ? (
              <FormFeedback type="invalid">
                {validation.errors.tradeLicenseNumber}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">
            Business Registration Number
          </Label>
          <Col sm={9}>
            <Input
              name="businessRegistrationNumber"
              type="text"
              placeholder="Enter business registration number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.businessRegistrationNumber || ""}
              invalid={
                validation.touched.businessRegistrationNumber &&
                validation.errors.businessRegistrationNumber
                  ? true
                  : false
              }
            />
            {validation.touched.businessRegistrationNumber &&
            validation.errors.businessRegistrationNumber ? (
              <FormFeedback type="invalid">
                {validation.errors.businessRegistrationNumber}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Company Address</Label>
          <Col sm={9}>
            <Input
              name="address"
              type="text"
              placeholder="Enter company address"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.address || ""}
              invalid={
                validation.touched.address && validation.errors.address
                  ? true
                  : false
              }
            />
            {validation.touched.address && validation.errors.address ? (
              <FormFeedback type="invalid">
                {validation.errors.address}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Contact Number</Label>
          <Col sm={9}>
            <Input
              name="contactNumber"
              type="text"
              placeholder="Enter contact number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.contactNumber || ""}
              invalid={
                validation.touched.contactNumber &&
                validation.errors.contactNumber
                  ? true
                  : false
              }
            />
            {validation.touched.contactNumber &&
            validation.errors.contactNumber ? (
              <FormFeedback type="invalid">
                {validation.errors.contactNumber}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="mb-3">
          <Label className="col-sm-3 col-form-label">Email Adddress</Label>
          <Col sm={9}>
            <Input
              name="email"
              type="text"
              placeholder="Enter email address"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email
                  ? true
                  : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">
                {validation.errors.email}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row className="justify-content-end">
          <Col sm={9}>
            <Button type="submit" color="primary" className="w-md">
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default CompanyProfile
