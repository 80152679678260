import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"

//import images
import propertyImage from "../../../assets/images/small/img-1.jpg"
import propertyImage1 from "../../../assets/images/small/img-2.jpg"
import propertyImage2 from "../../../assets/images/small/img-3.jpg"
import ImageGallery from "components/Common/ImageGallery"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const images = [propertyImage, propertyImage1, propertyImage2]

const DetailsSection = ({ data }) => {
  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const [isCompany, setIsCompany] = useState(false)
  const [images, setImages] = useState([])
  const [amendities, setAmendities] = useState([])

  useEffect(() => {
    setIsCompany(user.role === "company")
  }, [user])

  useEffect(() => {
    setImages(data.images ? data.images : [])
    setAmendities(data.Amendity ? data.Amendity : [])
  }, [data])
  return (
    <React.Fragment>
      <Col xl={8} xxl={9}>
        <div className="w-100 property-image-gallary mb-4">
          <div className="position-relative main">
            <img
              className="img img-fluid rounded-3 w-100 h-100"
              src={data?.images ? data.images[0] : ""}
            />
            <div className="position-absolute top-0 ps-2">
              <div className="rounded-3 text-white bg-primary fs-6 px-2 my-2 text-capitalize">
                <i className="bx bx-purchase-tag me-1" />
                {data?.status}
              </div>
              <div className="rounded-3 text-white bg-success fs-6 px-2 my-2 text-capitalize">
                <i className="bx bx-gift me-1" />
                {data?.furnished}
              </div>
            </div>
            <div className="position-absolute start-0 bottom-0 p-2">
              <ImageGallery images={images}>
                <Button className="btn-rounded btn bg-transparent me-2">
                  <i className="bx bx-camera" /> 3
                </Button>
              </ImageGallery>
            </div>
            <div className="position-absolute end-0 top-0 p-2">
              <Button className="btn-rounded btn bg-transparent">
                <i className="mdi mdi-heart text-danger" />
              </Button>
            </div>
            {isCompany && (
              <div className="position-absolute end-0 bottom-0 p-2">
                <Button className="btn-rounded btn btn-secondary me-2">
                  <i className="bx bx-pencil" />
                </Button>
                <Button className="btn-rounded btn btn-danger">
                  <i className="bx bx-trash" />
                </Button>
              </div>
            )}
          </div>
          <img
            className="img img-fluid rounded-3 w-100"
            src={data?.images ? data.images[1] : ""}
          />
          <img
            className="img img-fluid rounded-3 w-100"
            src={data?.images ? data.images[2] : ""}
          />
        </div>

        <Card>
          <CardBody>
            <Row>
              <Col lg={6}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h3 className="fw-semibold">
                      AED {data?.price ? data.price[0].value : ""}
                    </h3>
                    <span className="badge bg-success font-size-14 rounded-2">
                      <i className="bx bx-building-house"></i>{" "}
                      {data?.property_type?.title}
                    </span>
                  </div>
                </div>
              </Col>
              <Col lg={6} className="text-end">
                <ul className="list-unstyled hstack gap-4 mb-0 my-lg-0 mt-5 float-lg-end">
                  <li className="d-block text-center">
                    <i className="bx bx-bed fs-3 text-muted"></i>{" "}
                    <div className="fs-5 text-dark">
                      {data?.bedrooms} Bedrooms
                    </div>
                  </li>
                  <li className="d-block text-center">
                    <i className="bx bx-bath fs-3 text-muted"></i>{" "}
                    <div className="fs-5 text-dark">
                      {data?.bathrooms} Bathrooms
                    </div>
                  </li>
                  <li className="d-block text-center">
                    <i className="mdi mdi-vector-square fs-4 text-muted"></i>{" "}
                    <div className="fs-5 text-dark">{data?.size} sqft</div>
                  </li>
                </ul>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h4 className="fw-semibold mb-3">{data?.reference}</h4>
            <div
              className="text-muted"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></div>
          </CardBody>
        </Card>

        {amendities.length > 0 && (
          <Card>
            <CardBody>
              <h4 className="fw-semibold mb-3">Amendity</h4>
              <ul className="text-capitalize fs-6">
                {amendities.map((item, index) => (
                  <li key={index}>{item.title}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        )}
      </Col>
    </React.Fragment>
  )
}

export default DetailsSection
