import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//Dasboard
import Dashboard from "./dashboard/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//contacts
import contacts from "./contacts/reducer"

//chat
import chat from "./chat/reducer"

//contracts
import contracts from "./contracts/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  contacts,
  chat,
  contracts,
  Dashboard,
})

export default rootReducer
