import axios from "axios"

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVICE_URL}/api`,
})

axiosInstance.interceptors.request.use(config => {
  // Retrieve token from localStorage
  const token = localStorage.getItem("token")

  // If token is found
  if (token) {
    // Get request headers and if headers is undefined assign blank object
    // @ts-ignore
    config.headers = config.headers || {}

    // Set authorization header
    // ℹ️ JSON.parse will convert token to string
    config.headers.Authorization = token ? `Bearer ${token}` : ""
  }

  // Return modified config
  return config
})

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // If the response is successful, just return it
    return response
  },
  error => {
    // Check if the response status is 401
    if (error.response && error.response.status === 401) {
      // Redirect the user to the login page
      window.location.href = "/login" // Update with your login route
    }

    // Reject the promise with the error for further handling if needed
    return Promise.reject(error)
  }
)

export default axiosInstance
