export const USER_ROLE = {
  COMPNAY: 0,
  AGENT: 1,
}

export const SIGNER = {
  BUYER_AGENT: "buyer_agent",
  SELLER_AGENT: "seller_agent",
  BUYER_COMPANY: "buyer_company",
  SELLER_COMPANY: "seller_company",
}
