import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container } from "reactstrap"
import { createSelector } from "reselect"
import ContractsEditCompany from "./contracts-edit-company"
import ContractEditAgent from "./contract-edit-agent"
import { USER_ROLE } from "constants/enum"

const ContractEdit = () => {
  //meta title
  document.title = "Edit Contract Request | ListProperty"
  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const [isCompany, setIsCompany] = useState(false)

  useEffect(() => {
    setIsCompany(user.role === USER_ROLE.COMPNAY)
  }, [user])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Contract Requests" breadcrumbItem="Edit Request" />

          {isCompany ? <ContractsEditCompany /> : <ContractEditAgent />}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContractEdit
