import Breadcrumb from "components/Common/Breadcrumb"
import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//Import images
import avatar3 from "assets/images/users/avatar-4.jpg"

const NotificationsPage = () => {
  //meta title
  document.title = "Notifications | ListProperty"
  return (
    <Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Home" breadcrumbItem="Notifications" />
          <Card>
            <CardBody className="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="h6 mb-0 font-size-16">4 Notifications</div>
                <button className="btn bg-transparnet border-0 text-primary px-0">
                  <i className="mdi mdi-email-mark-as-unread" /> Mark all as
                  read
                </button>
              </div>
              <hr className="my-3" />
              <Link to="#" className="text-reset">
                <div className="justify-content-between d-flex my-2">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle">
                        <i className="bx bx-file" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1 fw-bold font-size-15">
                        New contract request.
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          James Worker sent you A2A contract request to you.
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" /> 3 min ago
                        </p>
                      </div>
                    </div>
                  </div>
                  <i className="mdi mdi-circle text-primary align-middle" />
                </div>
              </Link>
              <Link to="#" className="text-reset">
                <div className="justify-content-between d-flex  my-2">
                  <div className="d-flex">
                    <img
                      src={avatar3}
                      className="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1 fw-bold font-size-15">
                        New message.
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Salena Layfield sent you a message.
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" /> 30 min ago
                        </p>
                      </div>
                    </div>
                  </div>
                  <i className="mdi mdi-circle text-primary align-middle" />
                </div>
              </Link>
              <Link to="#" className="text-reset">
                <div className="justify-content-between d-flex my-2">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle">
                        <i className="bx bx-pen" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">New signature</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Salena Layfield signed A2A contract with you.
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" /> 1 hrs ago
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="#" className="text-reset">
                <div className="justify-content-between d-flex my-2">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle">
                        <i className="mdi mdi-account-check-outline" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">Approved contract request</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Argalon Technologies approved your A2A contract
                          request.
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" /> 1 days ago
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="#" className="text-reset">
                <div className="justify-content-between d-flex my-2">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-danger rounded-circle">
                        <i className="mdi mdi-account-cancel-outline" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">Rejected contract request</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Argalon Technologies rejected your A2A contract
                          request.
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" /> 1 weeks ago
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>

              <Row>
                <Col xs="12">
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin me-2" />
                      Load more
                    </Link>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}

export default NotificationsPage
