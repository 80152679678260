import React, { useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link, useNavigate } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"

const invalidPasswordMessage =
  "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."

const Register = props => {
  //meta title
  document.title = "Register | ListProperty"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: "",
      tradeLicenseNumber: "",
      businessRegistrationNumber: "",
      address: "",
      contactNumber: "",
      email: "",
      referralCode: "",
      password: "",
      retypePassword: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .required("Company Name is required")
        .max(100, "Company Name must be at most 100 characters"),
      tradeLicenseNumber: Yup.string()
        .required("Trade License Number is required")
        .matches(/^[A-Za-z0-9]+$/, "Trade License Number format is invalid"),
      businessRegistrationNumber: Yup.string()
        .required("Business Registration Number is required")
        .matches(
          /^[A-Za-z0-9]+$/,
          "Business Registration Number format is invalid"
        ),
      address: Yup.string()
        .required("Company Address is required")
        .max(200, "Company Address must be at most 200 characters"),
      contactNumber: Yup.string()
        .required("Contact Number is required")
        .matches(/^\d{10}$/, "Contact Number must be 10 digits"),
      email: Yup.string()
        .required("Email Address is required")
        .matches(
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          "Email format is invalid."
        ),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, invalidPasswordMessage)
        .matches(/[a-z]/, invalidPasswordMessage)
        .matches(/[0-9]/, invalidPasswordMessage)
        .matches(/[@$!%*?&#]/, invalidPasswordMessage),
      retypePassword: Yup.string()
        .required("Please retype your password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      referralCode: Yup.string().optional(),
    }),
    onSubmit: values => {
      dispatch(registerUser(values))
    },
  })

  const AccountProperties = createSelector(
    state => state.Account,
    account => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success,
      loading: account.loading,
    })
  )

  const { user, registrationError, success, loading } =
    useSelector(AccountProperties)

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 2000)
  }, [success])

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Skote account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register Company Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Company Name</Label>
                        <Input
                          id="companyName"
                          name="companyName"
                          className="form-control"
                          placeholder="Enter Company Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyName || ""}
                          invalid={
                            validation.touched.companyName &&
                            validation.errors.companyName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.companyName &&
                        validation.errors.companyName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.companyName}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          Trade License Number
                        </Label>
                        <Input
                          name="tradeLicenseNumber"
                          type="text"
                          placeholder="Enter trade license number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.tradeLicenseNumber || ""}
                          invalid={
                            validation.touched.tradeLicenseNumber &&
                            validation.errors.tradeLicenseNumber
                              ? true
                              : false
                          }
                        />
                        {validation.touched.tradeLicenseNumber &&
                        validation.errors.tradeLicenseNumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.tradeLicenseNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          Business Registration Number
                        </Label>
                        <Input
                          name="businessRegistrationNumber"
                          type="text"
                          placeholder="Enter business registration number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            validation.values.businessRegistrationNumber || ""
                          }
                          invalid={
                            validation.touched.businessRegistrationNumber &&
                            validation.errors.businessRegistrationNumber
                              ? true
                              : false
                          }
                        />
                        {validation.touched.businessRegistrationNumber &&
                        validation.errors.businessRegistrationNumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.businessRegistrationNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Company Address</Label>
                        <Input
                          name="address"
                          type="text"
                          placeholder="Enter company address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.address || ""}
                          invalid={
                            validation.touched.address &&
                            validation.errors.address
                              ? true
                              : false
                          }
                        />
                        {validation.touched.address &&
                        validation.errors.address ? (
                          <FormFeedback type="invalid">
                            {validation.errors.address}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Contact Number</Label>
                        <Input
                          name="contactNumber"
                          type="text"
                          placeholder="Enter contact number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.contactNumber || ""}
                          invalid={
                            validation.touched.contactNumber &&
                            validation.errors.contactNumber
                              ? true
                              : false
                          }
                        />
                        {validation.touched.contactNumber &&
                        validation.errors.contactNumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.contactNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email Adddress</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder="Enter email address"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Referral Code</Label>
                        <Input
                          name="referralCode"
                          type="text"
                          placeholder="Enter referral code"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.referralCode || ""}
                          invalid={
                            validation.touched.referralCode &&
                            validation.errors.referralCode
                              ? true
                              : false
                          }
                        />
                        {validation.touched.referralCode &&
                        validation.errors.referralCode ? (
                          <FormFeedback type="invalid">
                            {validation.errors.referralCode}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="retypePassword"
                          type="password"
                          placeholder="Retype Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.retypePassword || ""}
                          invalid={
                            validation.touched.retypePassword &&
                            validation.errors.retypePassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.retypePassword &&
                        validation.errors.retypePassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.retypePassword}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Skote{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
