import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { Card, CardBody, Col, Input, Label, Row, Table } from "reactstrap"
import { isEmpty } from "lodash"

//Import Image
import signature1 from "assets/images/signatures/signature1.png"
import signature2 from "assets/images/signatures/signature2.png"

import { toast } from "react-toastify"
import moment from "moment"
import {
  declineContract,
  getContractById,
  signContract,
} from "services/contractService"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { SIGNER } from "constants/enum"

const ContractEditCompany = props => {
  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)

  const goBack = e => {
    e.preventDefault()
    props.router.navigate(-1)
  }

  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState({})
  const [accepting, setAccepting] = useState(false)
  const [rejecting, setRejecting] = useState(false)

  const init = async id => {
    try {
      setLoading(true)
      const res = await getContractById(id)
      setDetail(res)
      setLoading(false)
    } catch (e) {
      toast.error(e.message)
    }
  }

  useEffect(() => {
    init(props.router.params.id)
  }, [props.router.params.id])

  //Print the contract
  const printContract = () => {
    window.print()
  }

  const acceptContract = async () => {
    try {
      setAccepting(true)

      // seller company approve
      if (detail.seller.company.email === user.email) {
        await signContract(
          Number(props.router.params.id),
          SIGNER.SELLER_COMPANY
        )
        toast.success(
          `Request to approve this A2A contract was sent to ${detail?.buyer?.company?.company_name}`
        )
        setAccepting(false)
        props.router.navigate("/contracts/requests")
      } else {
        await signContract(Number(props.router.params.id), SIGNER.BUYER_COMPANY)
        toast.success(`New contract has been established.`)
        setAccepting(false)
        props.router.navigate("/contracts")
      }
    } catch (e) {
      toast.error(e.message)
      setAccepting(false)
    }
  }

  const rejectContract = async () => {
    setRejecting(true)
    try {
      await declineContract(
        Number(props.router.params.id),
        "I can't accept this contract."
      )
      setRejecting(false)
      toast.success("You declined the contract.")
      props.router.navigate("/contracts/requests")
    } catch (e) {
      toast.error(e.message)
      setRejecting(false)
    }
  }

  return (
    <React.Fragment>
      {!isEmpty(detail) && (
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row className="d-flex align-items-center mx-0">
                  <Col lg={3} className="logo-text my-2">
                    <span className="fs-2 logo-lg">
                      <span className="logo-green">List</span>
                      <span className="text-dark">Property</span>
                    </span>
                  </Col>
                  <Col lg={6} className="text-center">
                    <div className="h4 text-uppercase">
                      Real estate regulatory agentcy
                    </div>
                    <div className="h2 text-capitalize">
                      AGENT to AGENT AGREEMENT
                    </div>
                    <div className="fw-semibold h6 mt-4">
                      As per the Real Estate Brokers By-Law No.(85) of 2006.
                    </div>
                  </Col>
                  <Col lg={3}>
                    <Row className="my-2">
                      <Col
                        sm={4}
                        className="fw-semibold text-sm-end text-start"
                      >
                        BRN:
                      </Col>
                      <Col sm={8} className="border-bottom border-dark">
                        {detail?.seller?.company?.business_registration_number}
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col
                        sm={4}
                        className="fw-semibold text-sm-end text-start"
                      >
                        STR#:
                      </Col>
                      <Col sm={8} className="border-bottom  border-dark">
                        {detail?.seller?.id}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Table className="table-responsive table-bordered border-dark">
                  <tbody>
                    <tr className="text-uppercase fw-bold fs-5">
                      <td colSpan={2} className="bg-info-light">
                        <div className="d-flex justify-content-between">
                          <div>Part1.</div>
                          <div>The parties</div>
                          <div>
                            Date:{" "}
                            <span className="fw-normal">
                              {moment(detail?.updated_at).format("MM/DD/YYYY")}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="text-uppercase fw-bold">
                      <td className="bg-secondary text-center">
                        A. The agent / broker{" "}
                        <span className="fw-normal">(seller's agent)</span>
                      </td>
                      <td className="bg-secondary text-center">
                        B. The agent / broker{" "}
                        <span className="fw-normal">(buyer's agent)</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Col xl={12}>
                          <div className="d-flex">
                            <div className="col-auto text-uppercase fw-semibold">
                              Name of establishment:
                            </div>
                            <div className="w-100 ms-2">
                              <div className="border-bottom border-dark">
                                {detail?.note}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Row className="my-1">
                          <Col
                            xl={2}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>address:</Label>
                          </Col>
                          <Col xl={10} className="col-auto">
                            <div className="border-bottom border-dark">
                              {detail?.seller?.company?.address}
                            </div>
                          </Col>
                        </Row>
                        <hr className="border-dark mb-2" />

                        <div className="text-uppercase fw-semibold mb-2">
                          Office contact detail
                        </div>
                        <Row>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>PH:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.company?.contact_number}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Fax:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.company?.fax ||
                                    "Not specified"}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Email:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.company?.email || ""}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>ORN:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.emirate_id}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Ded Lisc:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {
                                    detail?.seller?.company
                                      ?.trade_license_number
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>P.O. Box:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.company?.company_name || ""}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <div className="text-uppercase fw-semibold my-2">
                          Name of the registered agent
                        </div>
                        <Row>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Name:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.full_name}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>BRN:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {
                                    detail?.seller?.company
                                      ?.business_registration_number
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Date issued:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller_signed_at
                                    ? moment(detail?.seller_signed_at).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "Not signed yet"}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Mobile:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.contact_number}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>email:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.email}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <div className="mt-2 d-flex ">
                              <div className="col-auto text-uppercase fw-semibold">
                                seller's agent from a str#:
                              </div>
                              <div className="w-100 ms-2">
                                <div className="border-bottom border-dark">
                                  {detail?.seller?.id || ""}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <Col xl={12}>
                          <div className="d-flex">
                            <div className="col-auto text-uppercase fw-semibold">
                              Name of establishment:
                            </div>
                            <div className="w-100 ms-2">
                              <div className="border-bottom border-dark">
                                {detail?.note}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Row className="my-1">
                          <Col
                            xl={2}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>address:</Label>
                          </Col>
                          <Col xl={10} className="col-auto">
                            <div className="border-bottom border-dark">
                              {detail?.buyer?.company?.address}
                            </div>
                          </Col>
                        </Row>
                        <hr className="border-dark mb-2" />

                        <div className="text-uppercase fw-semibold mb-2">
                          Office contact detail
                        </div>
                        <Row>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>PH:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.company?.contact_number}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Fax:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.company?.fax ||
                                    "Not specified"}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Email:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.company?.email || ""}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>ORN:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.emirate_id}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Ded Lisc:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.company?.trade_license_number}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>P.O. Box:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.company?.company_name || ""}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <div className="text-uppercase fw-semibold my-2">
                          Name of the registered agent
                        </div>
                        <Row>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Name:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.full_name}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>BRN:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {
                                    detail?.buyer?.company
                                      ?.business_registration_number
                                  }
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row>
                              <Col
                                xl={4}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Date issued:</Label>
                              </Col>
                              <Col xl={8} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer
                                    ? moment(detail?.seller_signed_at).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "Not signed yet"}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>Mobile:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.contact_number}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col
                                xl={2}
                                className="col-auto text-uppercase text-xl-end text-start"
                              >
                                <Label>email:</Label>
                              </Col>
                              <Col xl={10} className="col-auto">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.email}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <div className="mt-2 d-flex ">
                              <div className="col-auto text-uppercase fw-semibold">
                                seller's agent from a str#:
                              </div>
                              <div className="w-100 ms-2">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.id || ""}
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-uppercase bg-secondary fw-bold text-center">
                        declaration by agent "A"
                      </td>
                      <td className="text-uppercase bg-secondary fw-bold text-center">
                        declaration by agent "B"
                      </td>
                    </tr>
                    <tr>
                      <td className="fst-italic text-center">
                        I hereby declare, I have read and understood the Real
                        Estate Broker Code of Ethics, I have a current signed
                        Seller's Agreement FORM A, I shall respond to a
                        reasonable offer to purchase the listed property from
                        Agent B, and shall not contact Agent B's Buyer nor
                        confer with their client under no circumstances unless
                        the nominated Buyer herein has already discussed the
                        stated listed property with our Office.
                      </td>
                      <td className="fst-italic text-center">
                        I hereby declare, I have read and understood the Real
                        Estate Broker Code of Ethics, I have a current signed
                        Seller's Agreement FORM A, I shall respond to a
                        reasonable offer to purchase the listed property from
                        Agent B, and shall not contact Agent B's Buyer nor
                        confer with their client under no circumstances unless
                        the nominated Buyer herein has already discussed the
                        stated listed property with our Office.
                      </td>
                    </tr>
                    <tr className="text-uppercase fw-bold fs-5">
                      <td colSpan={2} className="bg-info-light">
                        <div className="d-flex justify-content-evenly ">
                          <div className="d-flex">
                            <div>Part2.</div>
                            <div className="ms-2">The property</div>
                          </div>
                          <div className="d-flex">
                            <div>Part3.</div>
                            <div className="ms-2">
                              The commssion (
                              <span className="text-lowercase">split</span>)
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Row className="my-1">
                          <Col
                            xl={3}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>Property address:</Label>
                          </Col>
                          <Col xl={9} className="col-auto">
                            <div className="border-bottom border-dark">
                              {detail?.property?.location[0].address}
                            </div>
                          </Col>
                        </Row>
                        <hr className="border-dark mb-2" />
                        <Row className="my-1">
                          <Col
                            xl={3}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>Master developer</Label>
                          </Col>
                          <Col xl={9} className="col-auto">
                            <div className="border-bottom border-dark">
                              {detail?.property?.developer}
                            </div>
                          </Col>
                        </Row>
                        <hr className="border-dark mb-2" />
                        <Row className="my-1">
                          <Col
                            xl={3}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>Master project name:</Label>
                          </Col>
                          <Col xl={9} className="col-auto">
                            <div className="border-bottom border-dark">
                              {detail?.property?.project_name}
                            </div>
                          </Col>
                        </Row>
                        <hr className="border-dark mb-2" />

                        <div className="text-uppercase fw-semibold">
                          Property details:
                        </div>
                        <div className="text-capitalize mb-2">
                          listing agent to complete
                        </div>
                        <Row className="my-1">
                          <Col
                            xl={3}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>building name:</Label>
                          </Col>
                          <Col xl={9} className="col-auto">
                            <div className="border-bottom border-dark">
                              {detail?.property?.reference}
                            </div>
                          </Col>
                        </Row>
                        <Row className="my-1">
                          <Col
                            xl={3}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>listed price:</Label>
                          </Col>
                          <Col xl={9} className="col-auto">
                            <div className="border-bottom border-dark">
                              AED {detail?.property?.price[0].value}
                            </div>
                          </Col>
                        </Row>
                        <Row className="my-1">
                          <Col
                            xl={3}
                            className="col-auto text-uppercase text-xl-end text-start"
                          >
                            <Label>Description:</Label>
                          </Col>
                          <Col xl={9} className="col-auto">
                            <div className="border-bottom border-dark">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: detail?.property?.description,
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr className="border-dark mb-2" />
                        <Col xl={12}>
                          <Row className="my-1">
                            <Col xl={5} className="text-stard text-lg-end">
                              <Label className="horizontal-form-label text-uppercase mb-0">
                                Does a mou exist on this property?
                              </Label>
                            </Col>
                            <Col xl={7}>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  name="mou"
                                  defaultChecked
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  name="mou"
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={12}>
                          <Row className="my-1">
                            <Col xl={5} className="text-stard text-lg-end">
                              <Label className="horizontal-form-label text-uppercase mb-0">
                                Is the property tenanted?
                              </Label>
                            </Col>
                            <Col xl={7}>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  name="tenanted"
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  name="tenanted"
                                  defaultChecked
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Row>
                          <Col xl={12}>
                            The following commission split is agreed between the
                            Seller's Agent & Buyer's Agent.
                          </Col>
                          <Col xl={6} className="text-center">
                            <Row className="my-3">
                              <Col sm="auto" className="text-uppercase m-auto">
                                <Label className="me-2">Seller's agent:</Label>
                                {detail?.seller_commission}%
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6}>
                            <Row className="my-3">
                              <Col sm="auto" className="text-uppercase  m-auto">
                                <Label className="me-2">Buyer's agent:</Label>
                                {detail?.buyer_commission}%
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <div className="my-2 d-flex">
                              <div className="col-auto text-center">
                                <Label className="text-uppercase mb-0">
                                  Buyer's name:
                                </Label>
                                <div className="text">(Family name ONLY)</div>
                              </div>
                              <div className="w-100 ms-2">
                                <div className="border-bottom border-dark">
                                  {detail?.buyer?.full_name}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xl={12}>
                            <Row className="my-3">
                              <Col sm="auto" className="text-uppercase">
                                <Label className="">
                                  Transfer fee paid by:
                                </Label>
                              </Col>
                              <Col sm="auto">
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label text-uppercase">
                                    seller
                                  </label>
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="sellerTransferFee"
                                    defaultChecked={
                                      detail?.fee_paid_by
                                        ? detail?.fee_paid_by[0]
                                        : false
                                    }
                                  />
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label text-uppercase">
                                    Buyer
                                  </label>
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="buyerTransferFee"
                                    defaultChecked={
                                      detail?.fee_paid_by
                                        ? detail?.fee_paid_by[1]
                                        : false
                                    }
                                  />
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label text-uppercase">
                                    NEG
                                  </label>
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="neg"
                                    defaultChecked={
                                      detail?.fee_paid_by
                                        ? detail?.fee_paid_by[2]
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={12}>
                            <div className="my-3 d-flex">
                              <div className="col-auto text-center">
                                <Label className="text-uppercase mb-0">
                                  budget:
                                </Label>
                              </div>
                              <div className="w-100 ms-2">
                                <div className="border-bottom border-dark">
                                  AED {detail?.budget}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xl={12} className="my-3">
                            <div className="mb-2">
                              <Label className="horizontal-form-label text-uppercase mb-0">
                                Has the buyer had pre-finance approval?
                              </Label>
                            </div>
                            <div>
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  name="isPrefinance"
                                  defaultChecked={detail?.pre_finance}
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              &nbsp;
                              <div className="form-check form-check-inline">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  name="isPrefinance"
                                  defaultChecked={
                                    detail?.pre_finance ? false : true
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </div>
                          </Col>
                          <Col xl={12}>
                            <div className="my-3">
                              <div className="mb-2">
                                <Label className="horizontal-form-label text-uppercase mb-0">
                                  Has this buyer contacted the listing agent?
                                </Label>
                              </div>
                              <div>
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    className="form-check-input"
                                    name="isListingAgent"
                                    defaultChecked={detail?.listing_agent}
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    className="form-check-input"
                                    name="isListingAgent"
                                    defaultChecked={
                                      detail?.listing_agent ? false : true
                                    }
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-secondary" colSpan={2}>
                        <div className="text-uppercase fw-bold fs-5">
                          Part 4. Signatures
                        </div>
                        <Row className="d-flex align-items-center">
                          <Col xl={6} className="fw-semibold">
                            <div>Both Agents are required to co-operate</div>
                            <div>fully, complete this FORM & BOTH retain a</div>
                            <div>
                              full signed & stamped copy on file. List Property
                              is available to both parties(Office Stamps "x"
                              above)
                            </div>
                          </Col>
                          <Col xl={6}>
                            <div className="my-2">
                              <div className="d-flex">
                                <div className="col-auto text-center">
                                  <Label className="text-uppercase mb-0">
                                    Buyer Agent-
                                  </Label>
                                </div>
                                <div className="w-100 ms-2">
                                  <div className="border-bottom border-dark">
                                    <img
                                      src={signature2}
                                      alt="signature"
                                      height={25}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="my-2">
                              <div className="d-flex">
                                <div className="col-auto text-center">
                                  <Label className="text-uppercase mb-0">
                                    Seller Agent-
                                  </Label>
                                </div>
                                <div className="w-100 ms-2">
                                  <div className="border-bottom border-dark">
                                    {detail?.seller_signed_at ? (
                                      <img
                                        src={signature1}
                                        alt="signature"
                                        height={25}
                                      />
                                    ) : (
                                      "Not signed yet"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        className="text-center text-uppercase fw-semibold"
                      >
                        In the event agent a does not respond within 24 hours,
                        agent b must constact list property.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>

            <div className="d-print-none">
              <div className="mb-4">
                <Link
                  to="#"
                  onClick={printContract}
                  className="btn btn-secondary"
                >
                  <i className="fa fa-print" /> Print
                </Link>
                <Link to="#" className="btn btn-info ms-2">
                  <i className="fa fa-share-alt" /> Share
                </Link>

                {detail?.seller?.company?.email === user.email && (
                  <Link
                    to={`/contracts/requests/${detail?.id}/edit-by-company`}
                    className="btn btn-primary ms-2"
                  >
                    <i className="bx bx-edit-alt" /> Update
                  </Link>
                )}

                {detail?.seller?.company?.email === user.email ? (
                  <>
                    {/* Seller company can accept only when seller agent signed */}
                    {detail.seller_signed_at && (
                      <>
                        <button
                          type="button"
                          onClick={acceptContract}
                          disabled={accepting}
                          className="btn btn-success ms-2"
                        >
                          <i className="bx bx-send" />{" "}
                          {accepting ? "Submitting..." : "Accept"}
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* Buyer company can accept only when seller company signed */}
                    {detail &&
                      detail.reviewers.findIndex(
                        item => item.id === detail.seller.company.user_id
                      ) !== -1 &&
                      detail.seller_signed_at && (
                        <button
                          type="button"
                          onClick={acceptContract}
                          disabled={accepting}
                          className="btn btn-success ms-2"
                        >
                          <i className="bx bx-send" />{" "}
                          {accepting ? "Submitting..." : "Accept"}
                        </button>
                      )}
                  </>
                )}

                <button
                  type="button"
                  className="btn btn-danger ms-2"
                  onClick={rejectContract}
                  disabled={rejecting}
                >
                  <i className="mdi mdi-close" />{" "}
                  {rejecting ? "Submitting..." : "Reject"}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

ContractEditCompany.propTypes = {
  match: PropTypes.any,
}

export default withRouter(ContractEditCompany)
