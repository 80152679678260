import firebase from "firebase/compat/app"
import { getAnalytics } from "firebase/analytics"
import { updateProfile } from "firebase/auth"

// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/firestore"

// axios helper
import axios from "helpers/axios_helper"
import { USER_ROLE } from "constants/enum"

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      const app = firebase.initializeApp(firebaseConfig)
      // Aanalytic
      getAnalytics(app)

      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const role = await firebase
            .firestore()
            .collection("Users")
            .doc(user.uid)
            .get()
          this.updateStorage(
            user,
            role.data() ? role.data().role : USER_ROLE.COMPNAY
          )
        } else {
          localStorage.removeItem("authUser")
          localStorage.removeItem("token")
        }
      })
    }
  }

  /**
   * Store authentication token in storage
   * @param {*} user
   */
  updateStorage = async (user, role) => {
    try {
      if (user.getIdToken) {
        const token = await user.getIdToken()
        const userToBeStored = JSON.parse(JSON.stringify(user))
        Object.assign(userToBeStored, { role })
        localStorage.setItem("authUser", JSON.stringify(userToBeStored))
        localStorage.setItem("token", token)
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = userData => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(userData.email, userData.password)
        .then(
          () => {
            // update displayName
            updateProfile(firebase.auth().currentUser, {
              displayName: userData.companyName,
            })
              .then(async () => {
                const user = firebase.auth().currentUser
                this.updateStorage(user, USER_ROLE.COMPNAY)

                try {
                  // set user role
                  await firebase
                    .firestore()
                    .collection("Users")
                    .doc(user.uid)
                    .set({
                      role: USER_ROLE.COMPNAY, // Company
                    })

                  // register company to postgre
                  await axios.request({
                    url: "/users/create-company",
                    method: "POST",
                    data: userData,
                  })
                  resolve(user)
                } catch (error) {
                  reject(this._handleError(error))
                }
              })
              .catch(error => {
                reject(this._handleError(error))
              })
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    //localhost:3000/notifications
    http: return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          async user => {
            const role = await firebase
              .firestore()
              .collection("Users")
              .doc(user.uid)
              .get()
            this.updateStorage(
              user,
              role.data() ? role.data().role : USER_ROLE.COMPNAY
            )
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */

  socialLoginUser = async type => {
    let provider
    if (type === "google") {
      provider = new firebase.auth.GoogleAuthProvider()
    } else if (type === "facebook") {
      provider = new firebase.auth.FacebookAuthProvider()
    }
    try {
      const result = await firebase.auth().signInWithPopup(provider)
      const user = result.user
      return user
    } catch (error) {
      throw this._handleError(error)
    }
  }

  addNewUserToFirestore = user => {
    const collection = firebase.firestore().collection("users")
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    }
    collection.doc(firebase.auth().currentUser.uid).set(details)
    return { user, details }
  }

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
