import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { map } from "lodash"
import moment from "moment"

// FlatPickr
import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card
import CardContact from "./agent-card"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { getUsers as onGetUsers } from "../../store/contacts/actions"
import Spinners from "components/Common/Spinner"
import DeleteModal from "components/Common/DeleteModal"

import * as Yup from "yup"
import { useFormik } from "formik"
import { Line } from "rc-progress"
import { fileUpload, getExtension } from "helpers/utils"
import { toast } from "react-toastify"
import { createAgent, updateAgent } from "services/agentsService"

const ContactsGrid = () => {
  //meta title
  document.title = "User Grid | ListProperty"

  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const toggle = () => {
    setModal(!modal)
    validation.resetForm()
  }
  const [isLoading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const ContactsProperties = createSelector(
    state => state.contacts,
    Contacts => ({
      users: Contacts.users,
      loading: Contacts.loading,
    })
  )

  const { users, loading } = useSelector(ContactsProperties)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const invalidPasswordMessage =
    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
  const [selectedAgent, setSelectedAgent] = useState({})

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: selectedAgent?.full_name || "",
      title: selectedAgent?.title || "",
      phone: selectedAgent?.contact_number || "",
      email: selectedAgent?.email || "",
      password: selectedAgent?.password || "",
      dob: selectedAgent?.dob || "",
      license: selectedAgent?.license_id || "",
      emirate: selectedAgent?.emirate_id || "",
      avatar: selectedAgent?.avatar || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Full nameme is required")
        .max(100, "Full name must be at most 100 characters"),
      title: Yup.string()
        .required("Position/Title is required")
        .max(50, "Position/Title must be at most 50 characters"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email format is invalid.")
        .required("Email address is required"),
      phone: Yup.string()
        .required("Contact Number is required")
        .matches(/^\d{10}$/, "Contact Number must be 10 digits"),
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, invalidPasswordMessage)
        .matches(/[a-z]/, invalidPasswordMessage)
        .matches(/[0-9]/, invalidPasswordMessage)
        .matches(/[@$!%*?&#]/, invalidPasswordMessage),
      license: Yup.string().required("Real estate license is required."),
      dob: Yup.string().required("Date of birth is required."),
      emirate: Yup.string().required("Emirate ID is required."),
      avatar: Yup.string().optional(),
    }),
    onSubmit: async values => {
      setSubmitting(true)
      if (isEdit) {
        setIsEdit(false)
        try {
          await updateAgent(selectedAgent.id, {
            company_id: selectedAgent.company_id,
            ...values,
          })
          toggle()
        } catch (e) {
          toast.error(e)
        }
      } else {
        try {
          await createAgent(values)
          toggle()
        } catch (e) {
          toast.error(e)
        }
      }
      setSubmitting(false)
      dispatch(onGetUsers())
    },
  })

  useEffect(() => {
    dispatch(onGetUsers())
  }, [users.length])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  //delete agent
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = () => {
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    setDeleteModal(false)
  }

  const handleUserClicks = selected => {
    setIsEdit(true)
    setSelectedAgent(selected)
    setSelectedImage(selected.avatar)
    toggle()
  }

  const [uploadPercent, setUploadPercent] = useState(100)

  //  img upload
  const handleImageChange = event => {
    event.preventDefault()
    let reader = new FileReader()
    let file = event.target.files[0]
    reader.onloadend = () => {
      setSelectedImage(reader.result)
      fileUpload(
        "avatar",
        `${new Date().getTime()}.${getExtension(file.name)}`,
        file,
        percent => setUploadPercent(percent)
      )
        .then(res => validation.setFieldValue("avatar", res))
        .catch(e => toast.error(e))
    }
    reader.readAsDataURL(file)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Agents" />
          {/* Filter */}
          <Row>
            <Col lg={12}>
              <Card className="job-filter">
                <CardBody>
                  <Form>
                    <Row className="g-3">
                      <Col xxl={9} lg={8}>
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            id="searchJob"
                            placeholder="Search your agents"
                          />
                        </div>
                      </Col>

                      <Col xxl={3} lg={4}>
                        <div className="position-relative h-100 hstack gap-3">
                          <button
                            type="button"
                            className="btn btn-primary h-100 w-100"
                          >
                            <i className="bx bx-search-alt align-middle"></i>{" "}
                            Find Agents
                          </button>
                          <a
                            href="#"
                            onClick={toggle}
                            className="btn btn-success h-100 w-100"
                          >
                            <i className="bx bx-plus align-middle"></i> New
                            Agent
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Grid view */}
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <>
              <Row>
                {(users || [])?.map((user, key) => (
                  <CardContact
                    user={user}
                    key={"_user_" + key}
                    onEdit={handleUserClicks}
                    onRemove={onClickDelete}
                  />
                ))}
              </Row>
              <Row>
                <Col xs="12">
                  {/* <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin me-2" />
                      Load more
                    </Link>
                  </div> */}
                </Col>
              </Row>
            </>
          )}
          {/* Remove modal */}
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteUser}
            onCloseClick={() => setDeleteModal(false)}
          />
        </Container>

        {/* Edit modal */}
        <Modal isOpen={modal} toggle={toggle}>
          {uploadPercent !== 100 && (
            <Line
              percent={uploadPercent}
              strokeWidth="1"
              strokeColor="#2db7f5"
            />
          )}
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "Edit Agent" : "Add Agent"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
              }}
            >
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div className="position-absolute bottom-0 end-0">
                          <Label
                            htmlFor="project-image-input"
                            className="mb-0"
                            id="projectImageInput"
                          >
                            <div className="avatar-xs">
                              <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer shadow font-size-16">
                                <i className="bx bxs-image-alt"></i>
                              </div>
                            </div>
                          </Label>
                          <UncontrolledTooltip
                            placement="right"
                            target="projectImageInput"
                          >
                            Select Image
                          </UncontrolledTooltip>
                          <input
                            className="form-control d-none"
                            id="project-image-input"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleImageChange}
                          />
                        </div>
                        <div className="avatar-lg">
                          <div className="avatar-title bg-light rounded-circle">
                            <img
                              src={selectedImage || ""}
                              id="projectlogo-img"
                              alt=""
                              className="avatar-lg h-auto rounded-circle"
                            />
                          </div>
                        </div>
                      </div>
                      {validation.touched.avatar && validation.errors.avatar ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.avatar}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Full Name</Label>
                    <Input
                      name="name"
                      type="text"
                      placeholder="Enter full name."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Position/Title</Label>
                    <Input
                      name="title"
                      label="Position/Title"
                      placeholder="Enter position/title."
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.title}
                      invalid={
                        validation.touched.title && validation.errors.title
                          ? true
                          : false
                      }
                    />
                    {validation.touched.title && validation.errors.title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.title}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Email Address</Label>
                    <Input
                      name="email"
                      label="Email Address"
                      type="email"
                      placeholder="Enter email address."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Contact Number</Label>
                    <Input
                      name="phone"
                      label="Contact Number"
                      type="text"
                      placeholder="Enter contact number."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone}
                      invalid={
                        validation.touched.phone && validation.errors.phone
                          ? true
                          : false
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      label="Password"
                      type="password"
                      autoComplete="off"
                      placeholder="Enter password."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Date of Birth</Label>
                    <FlatPickr
                      className="form-control d-block"
                      id="dob"
                      name="dob"
                      placeholder="Select Date of Birth"
                      options={{
                        mode: "single",
                        dateFormat: "d M, Y",
                        maxDate: "today",
                      }}
                      onChange={customerdate =>
                        validation.setFieldValue(
                          "dob",
                          moment(customerdate[0]).format("DD MMMM ,YYYY")
                        )
                      }
                      value={validation.values.dob}
                    />
                    {validation.errors.dob && validation.touched.dob ? (
                      <FormFeedback type="invalid" className="d-block">
                        {validation.errors.dob}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Real Estate License</Label>
                    <Input
                      name="license"
                      label="Real Estate License"
                      type="text"
                      placeholder="Enter real estate license."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.license}
                      invalid={
                        validation.touched.license && validation.errors.license
                          ? true
                          : false
                      }
                    />
                    {validation.touched.license && validation.errors.license ? (
                      <FormFeedback type="invalid">
                        {validation.errors.license}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Emirate ID</Label>
                    <Input
                      name="emirate"
                      label="Emirate ID"
                      type="text"
                      placeholder="Enter emirate ID."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.emirate}
                      invalid={
                        validation.touched.emirate && validation.errors.emirate
                          ? true
                          : false
                      }
                    />
                    {validation.touched.emirate && validation.errors.emirate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.emirate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-start">
                    <button
                      type="submit"
                      disabled={submitting}
                      className="btn btn-success save-user"
                    >
                      {submitting ? "Submitting..." : "Save Agent"}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsGrid)
