import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"

//import images
import userImage from "../../../assets/images/users/avatar-4.jpg"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"
import { USER_ROLE } from "constants/enum"
import { toast } from "react-toastify"
import moment from "moment"
import { createContractRequest } from "services/contractService"

const Overview = props => {
  const [contractRequestModal, setContractRequestModal] = useState(false)
  const toggle = () => {
    setContractRequestModal(!contractRequestModal)
  }
  const { data } = props

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const [isCompany, setIsCompany] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setIsCompany(user.role === USER_ROLE.COMPNAY)
  }, [user])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      note: "",
      budget: "",
      termsAcceptance: "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("Note is required."),
      budget: Yup.number()
        .required("Budget is required")
        .typeError("Budget should be a number"),
      termsAcceptance: Yup.boolean()
        .oneOf([true], "You must accept the terms and conditions.")
        .required("You must accept the terms and conditions."),
    }),
    onSubmit: async values => {
      setLoading(true)
      const payload = {
        property_id: data.id,
        seller_agent_id: data.agent.id,
        seller_commission: 50.0,
        buyer_commission: 50.0,
        ...values,
      }
      try {
        await createContractRequest(payload)
        toast.success("Sent contract request to the seller agent")
        props.router.navigate("/contracts/requests")
      } catch (e) {
        toast.error(e.message)
      }
      setLoading(false)
    },
  })

  const saveRequest = () => {
    validation.handleSubmit()
  }

  return (
    <React.Fragment>
      <Col xl={4} xxl={3}>
        <Card>
          <CardBody>
            <div className="d-flex align-items-center">
              {data?.agent?.avatar ? (
                <img
                  src={data.agent.avatar}
                  alt=""
                  className="d-block avatar-lg rounded-circle"
                />
              ) : (
                <div className="avatar-lg">
                  <span className="avatar-title rounded-circle bg-primary text-white fs-3">
                    {data?.agent?.full_name.charAt(0)}
                  </span>
                </div>
              )}
              <div className="text-start ms-2">
                <h5 className="mb-2 h3">{data?.agent?.full_name}</h5>
                <ul className="list-unstyled mb-0">
                  <li className="my-2">
                    <div className="d-flex">
                      <i className="bx bx-building text-primary fs-4"></i>
                      <div className="ms-1">
                        <p className="text-muted fs-14 mb-0 text-capitalize">
                          {data?.company?.company_name}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="my-2">
                    <div className="d-flex">
                      <i className="bx bx-phone text-primary fs-4"></i>
                      <div className="ms-1">
                        <p className="text-muted fs-14 mb-0">
                          {data?.agent?.contact_number}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <i className="bx bx-mail-send text-primary fs-4"></i>
                      <div className="ms-1">
                        <p className="text-muted fs-14 mb-0">
                          {data?.agent?.email}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="hstack gap-2 mt-3">
              {isCompany ? (
                <div className="w-100">
                  <button className="btn btn-soft-primary d-flex align-items-center mx-auto">
                    <i className="bx bx-user-check me-1 font-size-16" /> Change
                    Agent
                  </button>
                </div>
              ) : (
                <>
                  {user.email !== data?.agent?.email && (
                    <>
                      <Link to="/chats" className="btn btn-soft-primary w-100">
                        <i className="mdi mdi-chat-processing-outline" /> Send
                        Message
                      </Link>
                      <button
                        className="btn btn-soft-success w-100"
                        onClick={toggle}
                      >
                        <i className="mdi mdi-handshake-outline" /> New
                        Agreement
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h5 className="fw-semibold">Property Overview</h5>

            <div className="table-responsive">
              <table className="table text-captialize">
                <tbody>
                  <tr>
                    <th scope="col">Type</th>
                    <td scope="col" className="text-capitalize">
                      {data?.property_type?.title}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Address</th>
                    <td>{data?.location ? data.location[0].address : ""}</td>
                  </tr>
                  <tr>
                    <th scope="row">Price</th>
                    <td>{data?.price ? data.price[0].value : ""} AED</td>
                  </tr>
                  <tr>
                    <th scope="row">Area Size</th>
                    <td>{data?.size} sqft</td>
                  </tr>
                  <tr>
                    <th scope="row">bedrooms</th>
                    <td>{data?.bedrooms}</td>
                  </tr>
                  <tr>
                    <th scope="row">Bathrooms</th>
                    <td>{data?.bathrooms}</td>
                  </tr>
                  <tr>
                    <th scope="row">Furnishing</th>
                    <td>
                      <span className="badge text-capitalize badge-soft-primary fs-6">
                        {data?.furnished}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      <span className="badge text-capitalize badge-soft-success fs-6">
                        {data?.status}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Floor Number</th>
                    <td>{data?.floor_number}</td>
                  </tr>
                  <tr>
                    <th scope="row">Total Floors</th>
                    <td>{data?.floors}</td>
                  </tr>
                  <tr>
                    <th scope="row">Posted At</th>
                    <td>{moment(data?.created_at).format("MMM DD, YYYY")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="hstack gap-2">
              <button className="btn btn-soft-primary w-100">
                <i className="bx bx-map" /> View on Map
              </button>
              <button className="btn btn-soft-success w-100">
                <i className="bx bx-landscape" /> Virtual Tour
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>

      {/* Contract Request Modal */}
      <Modal isOpen={contractRequestModal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle} tag="h4">
          New A2A Request
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col xl={12} className="mb-3">
                <Label className="form-label">Budget</Label>
                <InputGroup>
                  <Input
                    name="budget"
                    step={0.01}
                    type="number"
                    placeholder="Enter your budget"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.budget || ""}
                    invalid={
                      validation.touched.budget && validation.errors.budget
                        ? true
                        : false
                    }
                  />
                  <InputGroupText>AED</InputGroupText>
                </InputGroup>
                {validation.touched.budget && validation.errors.budget ? (
                  <FormFeedback type="invalid">
                    {validation.errors.budget}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={12} className="mb-3">
                <Label className="form-label">Note</Label>
                <Input
                  name="note"
                  type="textarea"
                  rows={3}
                  placeholder="Enter your note here"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.note || ""}
                  invalid={
                    validation.touched.note && validation.errors.note
                      ? true
                      : false
                  }
                />
                {validation.touched.note && validation.errors.note ? (
                  <FormFeedback type="invalid">
                    {validation.errors.note}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={12} className="mb-3">
                <Input
                  name="termsAcceptance"
                  type="checkbox"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.termsAcceptance || ""}
                  invalid={
                    validation.touched.termsAcceptance &&
                    validation.errors.termsAcceptance
                      ? true
                      : false
                  }
                />
                <Label className="form-label ms-1 mb-0">
                  I agree with the terms and conditions.
                </Label>
                {validation.touched.termsAcceptance &&
                validation.errors.termsAcceptance ? (
                  <FormFeedback type="invalid">
                    {validation.errors.termsAcceptance}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={12} className="">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-success save-user"
                >
                  {loading ? (
                    <>
                      <i className="mdi mdi-timer-sand" /> Submitting
                    </>
                  ) : (
                    <>
                      <i className="bx bx-send" /> Send Request
                    </>
                  )}
                </button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}
export default withRouter(Overview)
