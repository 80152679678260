import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { USER_ROLE } from "constants/enum"

const SidebarContent = props => {
  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const [isCompany, setIsCompany] = useState(false)

  useEffect(() => {
    setIsCompany(user.role === USER_ROLE.COMPNAY)
  }, [user])

  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
            </li>

            {isCompany && (
              <li>
                <Link to="/agents">
                  <i className="bx bxs-user-detail"></i>
                  <span>{props.t("Agents")}</span>
                </Link>
              </li>
            )}

            <li>
              <Link to="/#" className={"has-arrow"}>
                <i className="bx bx-receipt"></i>
                <span>{props.t("Properties")}</span>
              </Link>
              {user.role === USER_ROLE.COMPNAY ? (
                <ul className="sub-menu">
                  <li>
                    <Link to="/properties">{props.t("My Properties")}</Link>
                  </li>
                  <li>
                    <Link to="/properties/search">
                      {props.t("All Properties")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/properties/create">
                      {props.t("Create Property")}
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="sub-menu">
                  <li>
                    <Link to="/properties">{props.t("My Properties")}</Link>
                  </li>
                  <li>
                    <Link to="/properties/search">
                      {props.t("Search Property")}
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-file"></i>
                <span>{props.t("A2A Contracts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/contracts/requests">
                    <span className="badge rounded-pill bg-danger float-end">
                      1
                    </span>
                    <span>{props.t("Requests")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contracts">{props.t("Contracts")}</Link>
                </li>
                {/* {isCompany && (
                  <li>
                    <Link to="/terms">
                      <span>{props.t("Terms & Condition")}</span>
                    </Link>
                  </li>
                )} */}
              </ul>
            </li>

            <li>
              <Link to="/favorites">
                <i className="bx bx-heart"></i>
                <span>{props.t("Favorites")}</span>
              </Link>
            </li>

            <li>
              <Link to="/chats">
                <i className="bx bx-chat"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  10
                </span>
                <span>{props.t("Chat")}</span>
              </Link>
            </li>

            <li>
              <Link to="/notifications">
                <i className="bx bx-bell"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  2
                </span>
                <span>{props.t("Notifications")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-help-circle"></i>
                <span>{props.t("Help & Supports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/help-supports/faq">{props.t("FAQ")}</Link>
                </li>
                <li>
                  <Link to="/help-supports/supports">
                    {props.t("Contact Support")}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
