// Image
const invoiceList = [
  {
    id: 1,
    createdBy: "Luis Ted",
    requestCompany: "Linkedin",
    ownerCompany: "Argalon Techonology",
    ownAgent: "Edward Jobs",
  },
]

export { invoiceList }
