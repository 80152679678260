import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Overview from "./Overview"
import DetailsSection from "./DetailsSection"
import withRouter from "components/Common/withRouter"
import { toast } from "react-toastify"
import { getPropertyById } from "services/propertyService"

const PropertyDetails = ({ router }) => {
  document.title = "Property Details | ListProperty"

  const [detail, setDetail] = useState({})

  const init = async id => {
    try {
      const res = await getPropertyById(id)
      setDetail(res)
    } catch (e) {
      toast.error(e.message)
    }
  }

  useEffect(() => {
    init(router.params.id)
  }, [router.params.id])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Properties" breadcrumbItem="Property Details" />

          <Row>
            <DetailsSection data={detail} />
            <Overview data={detail} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(PropertyDetails)
