import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"

const Navbar = props => {
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [ecommerce, setecommerce] = useState(false)
  const [crypto, setcrypto] = useState(false)
  const [project, setproject] = useState(false)
  const [task, settask] = useState(false)
  const [contact, setcontact] = useState(false)
  const [blog, setBlog] = useState(false)
  const [job, setJob] = useState(false)
  const [candidate, setCandidate] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const [isCompany, setIsCompany] = useState(false)

  useEffect(() => {
    setIsCompany(user.role === "company")
  }, [user])

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-xl topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dashboard"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>

                {isCompany && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/agents"
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bxs-user-detail me-2"></i>
                      {props.t("Agents")}
                    </Link>
                  </li>
                )}

                {isCompany ? (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        e.preventDefault()
                        setcomponent(!component)
                      }}
                    >
                      <i className="bx bx-receipt me-2"></i>
                      {props.t("Properties")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: component,
                      })}
                    >
                      <Link
                        to="/properties"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("All Properties")}
                      </Link>
                      <Link
                        to="/properties/create"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Create Property")}
                      </Link>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item dropdown">
                    <Link
                      to="/properties"
                      className="nav-link dropdown-togglez arrow-none"
                    >
                      <i className="bx bx-receipt me-2"></i>
                      {props.t("Properties")}
                    </Link>
                  </li>
                )}

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setapp(!app)
                    }}
                  >
                    <i className="bx bx-file me-2"></i>
                    {props.t("A2A Contracts")}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link
                      to="/contracts/requests"
                      className="dropdown-item dropdown-toggle arrow-none"
                    >
                      <span className="badge rounded-pill bg-danger float-end">
                        12
                      </span>
                      <span>{props.t("Requests")}</span>
                    </Link>
                    <Link
                      to="/contracts"
                      className="dropdown-item dropdown-toggle arrow-none"
                    >
                      <span>{props.t("Contracts")}</span>
                    </Link>
                    <Link
                      to="/terms"
                      className="dropdown-item dropdown-toggle arrow-none"
                    >
                      {props.t("Terms & Condition")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/favorites"
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-heart me-2"></i>
                    {props.t("Favorites")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/chats"
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-chat me-2"></i>
                    <span className="badge rounded-pill bg-danger ms-1 float-end">
                      10
                    </span>
                    {props.t("Chats")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/notifications"
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-bell me-2"></i>
                    <span className="badge rounded-pill bg-danger ms-1 float-end">
                      2
                    </span>
                    {props.t("Notifications")}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setui(!ui)
                    }}
                  >
                    <i className="bx bx-help-circle me-2"></i>
                    {props.t("Help & Supports")}
                    <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: ui })}>
                    <Link
                      to="/help-supports/faq"
                      className="dropdown-item dropdown-toggle arrow-none"
                    >
                      <span>{props.t("FAQ")}</span>
                    </Link>
                    <Link
                      to="/help-supports/supports"
                      className="dropdown-item dropdown-toggle arrow-none"
                    >
                      <span>{props.t("Contact Support")}</span>
                    </Link>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
