import React, { useState, useEffect } from "react"

import PropertyList from "./PropertyList"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { getProperties } from "services/propertyService"
import { toast } from "react-toastify"
import { USER_ROLE } from "constants/enum"
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

const MyProperties = () => {
  const [loading, setLoading] = useState(true)

  const [modal, setModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  // validation
  const validation = useFormik({
    initialValues: {
      fullnameInput: "",
      emailInput: "",
      phoneNumberInput: "",
      uploadResume: "",
      messageInput: "",
    },
    validationSchema: Yup.object({
      fullnameInput: Yup.string().required("Please Enter Your Name"),
      emailInput: Yup.string().required("Please Enter Your Email"),
      phoneNumberInput: Yup.string().required("Please Enter Your Number"),
      uploadResume: Yup.string().required("Add Your Resume"),
      messageInput: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: values => {
      setModal()
      validation.resetForm()
    },
  })

  // pagination
  const [properties, setProperties] = useState([])

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      user: login.user,
    })
  )
  const { user } = useSelector(LoginProperties)
  const [isCompany, setIsCompany] = useState(false)

  const init = async () => {
    try {
      setLoading(true)
      const propertiesRes = await getProperties(user.role === USER_ROLE.COMPNAY)
      setProperties(propertiesRes)
      setLoading(false)
    } catch (e) {
      toast.error(e.message)
    }
  }

  useEffect(() => {
    setIsCompany(user.role === USER_ROLE.COMPNAY)
    init()
  }, [user])

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="job-filter">
            <CardBody>
              <Form>
                <Row className="g-3">
                  <Col xxl={3} lg={6}>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        id="searchJob"
                        placeholder="Search your properties"
                      />
                    </div>
                  </Col>

                  <Col xxl={2} lg={6}>
                    <div className="position-relative">
                      <select className="form-control">
                        <option>Property Type</option>
                        <option>Apartment</option>
                        <option>Villa</option>
                        <option>Commercial</option>
                        <option>Land</option>
                      </select>
                    </div>
                  </Col>

                  <Col xxl={2} lg={6}>
                    <div className="position-relative">
                      <select className="form-control">
                        <option>Status</option>
                        <option>Available</option>
                        <option>Sold</option>
                        <option>Rented</option>
                      </select>
                    </div>
                  </Col>

                  <Col xxl={2} lg={6}>
                    <div className="position-relative">
                      <select className="form-control">
                        <option>Furnishing Status</option>
                        <option>Furnished</option>
                        <option>Unfurnished</option>
                        <option>Semi-Furnished</option>
                      </select>
                    </div>
                  </Col>

                  <Col xxl={3} lg={6}>
                    <div className="position-relative h-100 hstack gap-3">
                      <button
                        type="button"
                        className="btn btn-primary h-100 w-100"
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                        Find Properties
                      </button>
                      <a
                        href="#"
                        onClick={toggle}
                        className="btn btn-secondary h-100 w-100"
                      >
                        <i className="bx bx-filter-alt align-middle"></i>{" "}
                        Advance
                      </a>
                    </div>
                  </Col>

                  <Collapse isOpen={isOpen} id="collapseExample">
                    <div>
                      <Row className="g-3">
                        <Col xxl={4} lg={6}>
                          <div>
                            <Label
                              htmlFor="price"
                              className="form-label fw-semibold"
                            >
                              Listing Price(AED)
                            </Label>
                          </div>
                          <Row className="d-flex align-items-center">
                            <Col sm="auto">
                              <Input type="number" placeholder="Min Price" />
                            </Col>
                            <Col sm="auto">-</Col>
                            <Col sm="auto">
                              <Input type="number" placeholder="Max Price" />
                            </Col>
                          </Row>
                        </Col>
                        <Col xxl={4} lg={6}>
                          <div>
                            <Label
                              htmlFor="area"
                              className="form-label fw-semibold"
                            >
                              Area Size(sqft)
                            </Label>
                          </div>
                          <Row className="d-flex align-items-center">
                            <Col sm="auto">
                              <Input type="number" placeholder="Min Size" />
                            </Col>
                            <Col sm="auto">-</Col>
                            <Col sm="auto">
                              <Input type="number" placeholder="Max Size" />
                            </Col>
                          </Row>
                        </Col>
                        <Col xxl={4} lg={4}>
                          <div className="position-relative">
                            <Label htmlFor="agent" className="fw-semibold">
                              Assigned Agent
                            </Label>
                            <Input
                              type="text"
                              id="agent"
                              autoComplete="off"
                              placeholder="Search by agent"
                            />
                            <i className="ri-government-line filter-icon"></i>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <PropertyList
        properties={properties}
        loading={loading}
        isCompany={isCompany}
        editable={true}
      />
    </React.Fragment>
  )
}

export default MyProperties
