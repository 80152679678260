import Breadcrumb from "components/Common/Breadcrumb"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Modal,
  Form,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Col,
  Label,
  Input,
  Row,
} from "reactstrap"
// Import Editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Link } from "react-router-dom"

const TermsAndConditions = () => {
  // validation
  const validation = useFormik({
    initialValues: {
      contractPurpose:
        "The purpose of this Agreement is to establish the terms and conditions under which the Agents will cooperate in the marketing and sale of real estate properties within the UAE.",
      commissionStructure: "3.5",
      paymentTerms: "Payment will be done with cryptocurrency.",
      responsibilites:
        "Each Agent shall:\
Ensure that all property listings are accurate and comply with local laws.\
Communicate promptly and professionally with each other regarding potential leads and transactions.\
Maintain confidentiality regarding all sensitive information related to clients and transactions.",
      terminationConditions:
        "This Agreement shall commence on the date first written above and continue until terminated by either party with 15 days written notice. Termination shall not affect any rights or obligations incurred prior to termination.",
      disputeResolution:
        "In the event of any dispute arising out of this Agreement, the Agents agree to first attempt to resolve the matter through good-faith negotiations. If the dispute cannot be resolved amicably, it shall be referred to mediation, and if still unresolved, to arbitration in accordance with the laws of the UAE.",
      governingLaw:
        "This Agreement shall be governed by and construed in accordance with the laws of the United Arab Emirates.",
    },
    validationSchema: Yup.object({
      contractPurpose: Yup.string().required(
        "Purpose of Agreement is required."
      ),
      commissionStructure: Yup.number()
        .typeError("Please enter a valid commission amount.")
        .required("Commission Structure is required."),
      paymentTerms: Yup.string().required("Payment Terms are required."),
      responsibilites: Yup.string().required(
        "Responsibilities of Agents are required."
      ),
      terminationConditions: Yup.string().required(
        "Termination Conditions are required."
      ),
      disputeResolution: Yup.string().required(
        "Dispute Resolution Process is required."
      ),
      governingLaw: Yup.string().required("Governing Law is required."),
    }),
    onSubmit: values => {},
  })
  const [modal, setModal] = useState(false)
  const toggle = () => {
    setModal(!modal)
  }

  //meta title
  document.title = "Terms and Conditions | ListProperty"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="Home" breadcrumbItem="Terms & Condition" />

          <Card>
            <CardBody>
              <div className="mb-3">
                <div className="fw-semibold font-size-15 text-capitalize">
                  1. contract purpose
                </div>
                <p>
                  The purpose of this Agreement is to establish the terms and
                  conditions under which the Agents will cooperate in the
                  marketing and sale of real estate properties within the UAE.
                </p>
              </div>

              <div className="my-3">
                <div className="fw-semibold font-size-15 text-capitalize">
                  2. agent responsibilities
                </div>
                <div>
                  Each Agent shall:
                  <ul>
                    <li>
                      Ensure that all property listings are accurate and comply
                      with local laws.
                    </li>
                    <li>
                      Communicate promptly and professionally with each other
                      regarding potential leads and transactions.
                    </li>
                    <li>
                      Maintain confidentiality regarding all sensitive
                      information related to clients and transactions.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="my-3">
                <div className="fw-semibold font-size-15 text-capitalize">
                  3. termination condition
                </div>
                <p>
                  This Agreement shall commence on the date first written above
                  and continue until terminated by either party with 15 days
                  written notice. Termination shall not affect any rights or
                  obligations incurred prior to termination.
                </p>
              </div>

              <div className="my-3">
                <div className="fw-semibold font-size-15 text-capitalize">
                  4. Dispute resolution
                </div>
                <p>
                  In the event of any dispute arising out of this Agreement, the
                  Agents agree to first attempt to resolve the matter through
                  good-faith negotiations. If the dispute cannot be resolved
                  amicably, it shall be referred to mediation, and if still
                  unresolved, to arbitration in accordance with the laws of the
                  UAE.
                </p>
              </div>

              <div className="my-3">
                <div className="fw-semibold font-size-15 text-capitalize">
                  5. Governing law
                </div>
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of the United Arab Emirates.
                </p>
              </div>

              <div className="my-3">
                <div className="fw-semibold font-size-15 text-capitalize">
                  6. Payment Terms
                </div>
                <p>Payment will be made via PayPal or cryptocurrency.</p>
              </div>

              <button className="btn btn-primary" onClick={toggle}>
                <i className="bx bx-edit-alt" /> Edit
              </button>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle} tag="h4">
          Edit terms and condition
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col xl={12} className="mb-3">
                <Label className="form-label">Commission Structure</Label>
                <Input
                  name="commisssionStructure"
                  type="number"
                  step={0.1}
                  placeholder="Enter commission structure."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.commissionStructure || ""}
                  invalid={
                    validation.touched.commissionStructure &&
                    validation.errors.contracommissionStructurectPurpose
                      ? true
                      : false
                  }
                />
                {validation.touched.commissionStructure &&
                validation.errors.commissionStructure ? (
                  <FormFeedback type="invalid">
                    {validation.errors.commissionStructure}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={6} className="mb-3">
                <Label className="form-label">Contract Purpose</Label>
                <Input
                  name="contractPurpose"
                  type="textarea"
                  rows={5}
                  placeholder="Enter contract purpose."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.contractPurpose || ""}
                  invalid={
                    validation.touched.contractPurpose &&
                    validation.errors.contractPurpose
                      ? true
                      : false
                  }
                />
                {validation.touched.contractPurpose &&
                validation.errors.contractPurpose ? (
                  <FormFeedback type="invalid">
                    {validation.errors.contractPurpose}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={6} className="mb-3">
                <Label className="form-label">Payment Terms</Label>
                <Input
                  name="paymentTerms"
                  type="textarea"
                  rows={5}
                  placeholder="Enter payment terms."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.paymentTerms || ""}
                  invalid={
                    validation.touched.paymentTerms &&
                    validation.errors.paymentTerms
                      ? true
                      : false
                  }
                />
                {validation.touched.paymentTerms &&
                validation.errors.paymentTerms ? (
                  <FormFeedback type="invalid">
                    {validation.errors.paymentTerms}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={6} className="mb-3">
                <Label className="form-label">Agent Responsibilities</Label>
                <Input
                  name="responsibilites"
                  type="textarea"
                  rows={5}
                  placeholder="Enter agent responsibilities."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.responsibilites || ""}
                  invalid={
                    validation.touched.responsibilites &&
                    validation.errors.responsibilites
                      ? true
                      : false
                  }
                />
                {validation.touched.responsibilites &&
                validation.errors.responsibilites ? (
                  <FormFeedback type="invalid">
                    {validation.errors.responsibilites}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={6} className="mb-3">
                <Label className="form-label">Termination Conditions</Label>
                <Input
                  name="terminationConditions"
                  type="textarea"
                  rows={5}
                  placeholder="Enter termination conditions."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.terminationConditions || ""}
                  invalid={
                    validation.touched.terminationConditions &&
                    validation.errors.terminationConditions
                      ? true
                      : false
                  }
                />
                {validation.touched.terminationConditions &&
                validation.errors.terminationConditions ? (
                  <FormFeedback type="invalid">
                    {validation.errors.terminationConditions}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={6} className="mb-3">
                <Label className="form-label">Dispute Resolution</Label>
                <Input
                  name="disputeResolution"
                  type="textarea"
                  rows={6}
                  placeholder="Enter dispute resolution."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.disputeResolution || ""}
                  invalid={
                    validation.touched.disputeResolution &&
                    validation.errors.disputeResolution
                      ? true
                      : false
                  }
                />
                {validation.touched.disputeResolution &&
                validation.errors.disputeResolution ? (
                  <FormFeedback type="invalid">
                    {validation.errors.disputeResolution}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={6} className="mb-3">
                <Label className="form-label">Governing Law</Label>
                <Input
                  name="governingLaw"
                  type="textarea"
                  rows={6}
                  placeholder="Enter governing law."
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.governingLaw || ""}
                  invalid={
                    validation.touched.governingLaw &&
                    validation.errors.governingLaw
                      ? true
                      : false
                  }
                />
                {validation.touched.governingLaw &&
                validation.errors.governingLaw ? (
                  <FormFeedback type="invalid">
                    {validation.errors.governingLaw}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xl={12}>
                <button type="submit" className="btn btn-success">
                  <i className="bx bx-save" /> Update
                </button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default TermsAndConditions
