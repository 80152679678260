import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CONTRACTS, GET_CONTRACT_DETAIL } from "./actionTypes"
import {
  getContractsSuccess,
  getContractsFail,
  getContractDetailSuccess,
  getContractDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getContracts, getContractDetail } from "helpers/fakebackend_helper"

function* fetchContracts() {
  try {
    const response = yield call(getContracts)
    yield put(getContractsSuccess(response))
  } catch (error) {
    yield put(getContractsFail(error))
  }
}

function* fetchContractDetail({ invoiceId }) {
  try {
    const response = yield call(getContractDetail, invoiceId)
    yield put(getContractDetailSuccess(response))
  } catch (error) {
    yield put(getContractDetailFail(error))
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_CONTRACTS, fetchContracts)
  yield takeEvery(GET_CONTRACT_DETAIL, fetchContractDetail)
}

export default invoiceSaga
